/* eslint-disable eqeqeq */
import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Header } from "../../components";
import Delete from "../../components/DeleteConfirmModal/DeleteModal";
import {
  deleteUnifiedUserAddress,
  editAddressInfo,
  getAllAddressInfo,
  orderPaymentStatus,
} from "../../services/Collection";
import { LoadingWapper } from "../../style/GobalStyle";
import IntlMessage from "../../Utils/IntlMessage";
import { CartAddressBtn } from "../CartWithAddress/CartWithAddressStyle";
import {
  SelectAddressParent,
  SelectLocationContentBox,
  SelectLocationWrapper,
} from "./SelectLocationStyle";
import { updateAddress } from "../../store/DeliveryModule/DeliverySlice";
import GoogleMapReact from "google-map-react";
import { CurrentLocationIcon, DeleteIcon } from "../../Utils/Icons";

export const SelectDeliveryLocation = () => {
  const dir = useSelector((state) => state?.selectLang?.direction);
  const [openDelete, setOpenDelete] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const currentID = currentActive?.[0]?.qrcodeid;
  const orderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [deleteid, setDeleteid] = useState(null);
  const [customerLat, setCustomerLat] = useState(null);
  const [customerLong, setCustomerLong] = useState(null);
  const RestaurentData = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const customTheme = useSelector((state) => state?.theme?.branding);

  const getAllAddress = async () => {
    setLoading(true);
    let res = await getAllAddressInfo();
    if (res.status == 200) {
      setAddressData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.message);
    }
  };

  const SelectedAddress = () => {
    if (defaultAddress) {
      let selected = addressData.filter((el) => el?.id == defaultAddress);
      setCustomerLat(selected?.[0]?.latitude);
      setCustomerLong(selected?.[0]?.longitude);
      dispatch(updateAddress(defaultAddress));
    } else {
      toast.error("Please select address", { theme: "colored" });
    }
  };

  const handleDeleteAddress = async (addressId) => {
    setLoading(true);
    setOpenDelete(false);
    let res = await deleteUnifiedUserAddress(addressId);
    if (res.status === 200) {
      toast.success(res?.message);
      setLoading(false);

      getAllAddress();
    } else {
      toast.error(res?.message);
      setLoading(false);
    }
  };

  const EditAddress = async (value) => {
    setLoading(true);
    let req = {
      full_address: value?.full_address,
      house_number: value?.house_number,
      street: value?.street,
      city: value.city,
      country: value.country,
      extra_detail: value?.extra_detail,
      isPrimary: true,
      address_id: value?.id,
      latitude: value?.latitude,
      longitude: value?.longitude,
    };
    let res = await editAddressInfo(req);
    if (res.status === 200) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      getAllAddress();
    } else {
      setLoading(false);
      toast.error(res.message, { theme: "colored" });
    }
  };

  const handleModalOpenClose = (el) => {
    setOpenDelete(true);
    setDeleteid(el?.id);
  };

  const handlePlaceOrderAndCheckDevilery = (distance) => {
    if (orderDetails?.order_type === "Delivery") {
      let distanceNumber = distance?.split(" ")?.[0];
      let checkDistanceUnit = distance?.split(" ")?.[1];
      let RestaurantDistance;

      if (checkDistanceUnit === "km") {
        RestaurantDistance = RestaurentData?.max_delivery_distance;
      } else if (checkDistanceUnit === "m") {
        RestaurantDistance = RestaurentData?.max_delivery_distance * 1000;
      }
      if (Number(RestaurantDistance) >= Number(distanceNumber)) {
        navigate(`/${currentID}/pre-payment`);
      } else {
        toast.warning(
          // `Restaurant is only delivery within ${RestaurentData?.max_delivery_distance} KM.`,
          `We apologize, but we do not offer delivery in your area`,
          { theme: "colored" }
        );
      }
    }
  };

  const calculateDistance = (directionsService, origin, destination) => {
    setCustomerLat(null);
    setCustomerLong(null);
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: "DRIVING",
      },
      (result, status) => {
        if (status === "OK") {
          // setDistance(result.routes[0].legs[0].distance.text);
          handlePlaceOrderAndCheckDevilery(
            result.routes[0].legs[0].distance.text
          );
          // console.log(result.routes[0].legs[0].distance.text, "result.routes[0].legs[0].distance.text")
        } else {
          dir === "ltr"
            ? toast.error(
                "Google map is unavailable right now, Please try again"
              )
            : toast.error("خريطة جوجل غير متاحة الآن ، يرجى المحاولة مرة أخرى");
        }
      }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMapApiLoaded = ({ maps }) => {
    const directionsService = new maps.DirectionsService();
    const origin = new maps.LatLng(
      RestaurentData?.latitude,
      RestaurentData?.longitude
    );
    const destination = new maps.LatLng(customerLat, customerLong);
    calculateDistance(directionsService, origin, destination);
  };

  // const Maps = useMemo(() => {
  //   if (customerLong && customerLat) {
  //     return (
  //       < div style={{ width: 0, height: 0 }}>
  //         <GoogleMapReact
  //           bootstrapURLKeys={{ key: "AIzaSyA3sQBgGGclgBv7qXQNX7r55YWoEJfTHHU" }}
  //           defaultCenter={{ lat: 30.361, lng: 76.8485 }} // San Francisco, CA
  //           defaultZoom={10}
  //           onGoogleApiLoaded={onMapApiLoaded}
  //         />
  //       </div>
  //     )
  //   }
  // }, [customerLong, customerLat])

  const Hello = () => {
    return (
      <div style={{ width: 0, height: 0 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyA3sQBgGGclgBv7qXQNX7r55YWoEJfTHHU" }}
          defaultCenter={{ lat: 30.361, lng: 76.8485 }} // San Francisco, CA
          defaultZoom={10}
          onGoogleApiLoaded={onMapApiLoaded}
        />
      </div>
    );
  };

  useEffect(() => {
    getAllAddress();
  }, []);

  return (
    <SelectLocationWrapper dir={dir}>
      {openDelete && (
        <Delete
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          payload={deleteid}
          handleConfirm={handleDeleteAddress}
        />
      )}
      <div className="location-Header">
        <Header />
      </div>
      {/* {Maps} */}
      {customerLong && customerLat && <Hello />}

      {loading ? (
        <LoadingWapper style={{ width: "100%", height: "80vh" }}>
          <CircularProgress sx={{ color: customTheme?.theme_color }} />
        </LoadingWapper>
      ) : (
        <SelectLocationContentBox>
          <div
            onClick={() => navigate(`/${currentID}/map-location`)}
            className="Current-Location-box"
          >
            <div className="Location-icon">
              <CurrentLocationIcon color={customTheme?.theme_color}/>
            </div>
            <div
              className="Location-title"
              style={{ color: customTheme?.theme_color }}
            >
              <h4 style={{ color: "inherit" }}>Use Current Location</h4>
              <h6 style={{ color: "inherit" }}>Using GPS</h6>
            </div>
          </div>

          <SelectAddressParent
            dir={dir}
            style={{ background: customTheme?.background_color }}
          >
            <div className="address-title">
              <label
                className="title"
                style={{ color: customTheme?.header_color }}
              >
                Your Address
              </label>
            </div>

            {addressData.length > 0 &&
              addressData?.map((el) => (
                <div className="Selected-address">
                  <div className="SelectedAddress-title">
                    <input
                      style={{ accentColor: customTheme?.theme_color }}
                      type="radio"
                      name="selectAddress"
                      value={el.id}
                      checked={defaultAddress == el.id ? true : false}
                      onChange={(e) => setDefaultAddress(e.target.value)}
                    />
                    <div className="title-Wrapper">
                      <h3>House No - {el?.house_number}</h3>
                    </div>
                    {el?.primary ? (
                      <div className="default-btn">
                        <span
                          style={{
                            color: customTheme?.button_text_color,
                            background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                          }}
                        >
                          <b>Default</b>
                        </span>
                      </div>
                    ) : (
                      <div className="default-btn-gray">
                        <span onClick={() => EditAddress(el)}>Default</span>
                      </div>
                    )}

                    <div
                      onClick={() => handleModalOpenClose(el)}
                      className="delete-icon-d"
                    >
                      <DeleteIcon color={customTheme?.theme_color} />
                    </div>
                  </div>
                  <div className="SelectedAddress-content">
                    <p>
                      {el?.street}, {el?.city}, {el?.state}, {el?.country} -{" "}
                      {el?.pin_code}
                    </p>

                    <div className="edit-btn">
                      <p
                        style={{ color: customTheme?.theme_color }}
                        onClick={() =>
                          navigate(`/${currentID}/add-location`, {
                            state: { ...el, prevScreen: "select-location" },
                          })
                        }
                      >
                        Edit
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </SelectAddressParent>
        </SelectLocationContentBox>
      )}
      <CartAddressBtn>
        <div
          className="ReviewOrderButton"
          onClick={SelectedAddress}
          style={{
            color: customTheme?.button_text_color,
            background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
          }}
        >
          <p style={{ color: "inherit" }}>
            <IntlMessage id="selectLocation.button.useThisLocation" />
          </p>
        </div>
      </CartAddressBtn>
    </SelectLocationWrapper>
  );
};
