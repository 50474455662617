import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import IntlMessage from "../../Utils/IntlMessage";
import { updatePaymentMethod } from "../../store/DeliveryModule/DeliverySlice";

function ViewBasket({
  handleViewAction,
  ViewText,
  isLoadingButton,
  style,
  identifyFoodModal,
  totalPriceFromItemDrawer,
  isCashOnArrival,
  handleCashOnArrival,
}) {
  const direction = useSelector((state) => state.selectLang.direction);
  const { totalPrice, totalCount } = useSelector(({ cartSlice }) => ({
    ...cartSlice,
  }));
  const { restaurentData } = useSelector(({ restaurentSlice }) => ({
    ...restaurentSlice,
  }));
  const location = useLocation();
  // const RestaurentTheme = useSelector(
  //   (state) =>
  //     state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.MenuPageBranding
  // );
  // const RestaurentTheme = useSelector((state) => state?.theme?.branding);
  const RestaurentTheme = useSelector((state) => state?.theme?.branding);
  const dispatch = useDispatch();
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const isDeliveryEnable = useSelector((state) => state?.deliveryModule);

  const currentID = currentActive?.[0]?.qrcodeid;
  const customTheme = useSelector((state) => state?.theme?.branding);

  const unifiedDetails = useSelector((state) => state?.isUnifiedSlice);
  const selectedType = useSelector((state) => state?.deliveryModule);

  console.log("selectedType", selectedType);
  var ButtonColour = null;
  if (location?.pathname.includes("cart")) {
    let PlaceOrderButtonColor = {
      PrimaryColor:
        RestaurentTheme?.place_order_primary_button_colour || "#000",
      SecondaryColor:
        RestaurentTheme?.place_order_secondary_button_colour || "000",
    };
    ButtonColour = PlaceOrderButtonColor;
  } else {
    let PlaceOrderButtonColor = {
      PrimaryColor: RestaurentTheme?.review_primary_button_colour || "000",
      SecondaryColor: RestaurentTheme?.review_secondary_button_colour || "000",
    };
    ButtonColour = PlaceOrderButtonColor;
  }

  const isUserOnMainPage = false;

  var Count = 0;
  var Price = 0;

  if (restaurentData?.AddToCarts) {
    restaurentData?.AddToCarts?.map((ele) => {
      Count = Count + Number(ele.qty);
      Price = Price + Number(ele.price);
    });
  }
  const { quantity } = useSelector((state) => state.selectItemSlice);

  function AmountWithDecimalValue(x) {
    let n = Number(x).toFixed(2);
    if (n) {
      let res = n.split(".");
      if (res) {
        if (Number(res[1]) > 0) {
          return n;
        } else {
          return res[0];
        }
      }
    }
  }

  const handlePaymentMethod = (type) => {
    if (
      location?.pathname?.includes("/cart") &&
      restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
      selectedType?.orderType === "delivery"
    ) {
      dispatch(updatePaymentMethod(type));
    }
  };

  return (
    <ViewBasketAtFooter
      style={{
        ...style,
        background: customTheme?.background_color,
        border: `1px solid ${customTheme?.background_color}20`,
      }}
      dir={direction}
      ButtonColour={ButtonColour}
    >
      <div className="TotalPrice">
        <label style={{ color: customTheme?.text_color }}>
          <IntlMessage id="AddItemDrawer.totalprice" />
        </label>
        <label style={{ color: customTheme?.text_color }}>
          {location?.pathname == `/${currentID}/cart` &&
          isDeliveryEnable?.orderType === "delivery" ? (
            <>
              QAR{" "}
              {AmountWithDecimalValue(
                parseFloat(
                  identifyFoodModal
                    ? totalPriceFromItemDrawer
                    : isUserOnMainPage
                    ? Price
                    : totalPrice
                ) + parseFloat(restaurentData?.Menu?.Restaurent?.delivery_fee)
              )}
            </>
          ) : (
            <>
              QAR{" "}
              {AmountWithDecimalValue(
                parseFloat(
                  identifyFoodModal
                    ? totalPriceFromItemDrawer
                    : isUserOnMainPage
                    ? Price
                    : totalPrice
                )
              )}
            </>
          )}
        </label>
      </div>

      <div
        className="ReviewOrderButton"
        onClick={() => {
          handleViewAction();
          handlePaymentMethod(1);
        }}
        style={{
          color: customTheme?.button_text_color,
          background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
        }}
      >
        <span style={{ color: customTheme?.button_secondary_color }}>
          {identifyFoodModal ? quantity : isUserOnMainPage ? Count : totalCount}
        </span>
        <p style={{ color: "inherit" }}>
          {" "}
          {ViewText ? ViewText : <IntlMessage id="cart.button.reviewOrder" />}
        </p>
      </div>

      {isCashOnArrival &&
        restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
        restaurentData?.Menu?.Restaurent?.cash_on_arrival &&
        selectedType?.orderType === "takeaway" && (
          <div
            className="ReviewOrderButton"
            onClick={handleCashOnArrival}
            style={{
              margin: "10px 0",
              color: customTheme?.button_text_color,
              background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
            }}
          >
            <p style={{ color: "inherit" }}>
              <IntlMessage id="cart.button.payUponArrival" />
            </p>
          </div>
        )}

      {isCashOnArrival &&
        restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
        restaurentData?.Menu?.Restaurent?.cash_on_delivery &&
        selectedType?.orderType === "delivery" && (
          <div
            className="ReviewOrderButton"
            onClick={() => {
              handleCashOnArrival();
              handlePaymentMethod(2);
            }}
            style={{
              margin: "10px 0",
              color: customTheme?.button_text_color,
              background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
            }}
          >
            <p style={{ color: "inherit" }}>
              {/* <IntlMessage id="cart.button.payUponArrival" /> */}
              Cash on delivery
            </p>
          </div>
        )}
    </ViewBasketAtFooter>
  );
}

export default ViewBasket;

const ViewBasketAtFooter = styled.div`
  width: 30%;
  background: #ffffff;
  border: 1px solid #f7faff;
  box-shadow: 0px -8px 20px rgba(0, 0, 0, 0.08);
  padding: 15px 30px;
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  @media (max-width: 1024px) {
    width: 100%;
  }

  .TotalPrice {
    display: flex;
    justify-content: space-between;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #242424;
    margin: 0 0 10px 0;
  }

  .ReviewOrderButton {
    width: 100%;
    height: 48px;
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      position: absolute;
      width: 34px;
      height: 34px;
      background: #ffffff;
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 7px;
      left: ${({ dir }) => dir === "ltr" && "5px"};
      right: ${({ dir }) => dir === "rtl" && "5px"};
      border-radius: 100%;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.06px;
      color: #ffffff;
    }
  }
`;

const ViewBasketParent = styled.div`
  display: flex;
  justify-content: space-between;

  height: 60px;
  /* margin: 40px 0 0 0; */
  // position: fixed;
  // bottom: 0;
  // right: 0;
  // left: 0;
  margin: 50px 0 0 0;

  .ItemDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    .count {
      position: absolute;
      top: 10%;
      background: #000000;
      color: white;
      border-radius: 50%;
      right: 0%;
      padding: 4px 5px;
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 12.5px;
      line-height: 14px;
    }
  }

  .ItemDetails img {
    margin: 12px;
  }

  .Itemturn {
    margin-left: 10px;
    color: #e9e9e9;
  }

  .ViewItem {
    button {
      border-radius: 6.25377px;
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 12.3512px;
      line-height: 18px;
      letter-spacing: 0.05em;
      color: #ffffff;

      background: linear-gradient(115.41deg, #f55a2c 20.05%, #f55a2c 85.17%);
      border: 1px solid #ffffff;
      border-radius: 6.7806px;
      margin: 11px;
      padding: 8px;
      /* margin-left: 6px; */
      margin-right: 17px;
    }
  }
`;
