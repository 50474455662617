import { postApi, getApi, putApi, deleteApi } from "./ApiMethod";

const SHOW_MENU_BY_ID = "restaurents/getMenuById";
const SHOW_MENU = "restaurents/getQrCodeDetails";
//Here I am using getOrder details api of restaurents side
const GET_ORDER_DETAILS = "/customer/getOrderDetailForCustomer";
const ADD_TO_CART = "customer/addToCart";
const VIEW_CART = "customer/viewCartItem";
const DELETE_CART_ITEM = "customer/deleteCartItem";
const ADD_RECOMMENDED_ITEM_FROM_CART = "/customer/addToCartToRecommendedItem";
const CART_ITEM_QTY = "/customer/updateItemQtyIncDesc";
const CART_RECOMMENDED_ITEM_QTY = "/customer/incDecRecommendedItemCount";
const PLACE_ORDER = "/customer/placeOrderByCustomer";

const GET_WAITER = "/customer/messagePassToUser";
const UPDATE_CART = "/customer/updateCartByCustomer";
const ITEM_OFFER_DETAILS = "/customer/getItemDiscountDetails";
const GET_TOTAL_DISCOUNT = "/customer/getOverAllDiscount";
const GET_RECOMENDED_NOT_IN_CART_ITEMS = "/customer/getRecommendedNotIncart";
const GET_UPDATED_ORDER_DETAILS = "/customer/updatedOrderDetails";
const GET_RESTAURENT_STATUS = "/customer/restaurentOperationalDetails";
const ORDER_RECEIPT = "restaurents/getOrderDetailsByUser";

const FEEDBACK_BY_CUSTOMER = "customer/feedbackByCustomer";
const SEND_EMAIL_RECEPIT = "customer/sendEmailReceipts";
const POS_PAYMENT = "customer/posPayment";
const PAYMENT_SKIPCASH = "customer/paymentInitiate";
const SYNC_POS = "customer/syncpos";
const SYNC_ORDER_PAYEMNT = "customer/SyncOrderPayment";
const CHECK_AURACOUSTOMER_EXISTS = "delivery/checkCustomerExists";
const POS_SYNC_STATUS = "customer/updatePosSyncStatus";
const MASTER_QRCODE_DETAILS = "restaurents/getMaterQrCodeDetails";
const GET_SEARCH_DETAILS = "customer/searchRestauerentItem";
const GET_UNIFIED_USER_INFO = "delivery/getCustomerInfo";
const CREATE_ADDRESS_INFO = "delivery/createAddress";
const EDIT_ADDRESS_INFO = "delivery/editAddress";
const GET_ALL_ADDRESS_INFO = "delivery/getAllAddress";
const DEBIT_REDEEM_POINTS = "delivery/debitPoints";
const ORDER_PAYMENT_STATUS = "delivery/changeOrderPaymmentStatus";
const RESTAURENT_QRCODEID = "restaurents/restaurentInfo";
const RESTAURENT_ID = "restaurents/qrcodeInfo";
const DELETE_USER_ADDRESS = "delivery/deleteAddress";
const VERIFY_POS_CHECK_STATUS = "customer/verifyPosCheckStatus";
const UPDATE_RESTAURANT_WEB = "customer/updateRestaurantWeb";
const VALIDATE_AUTH_CODE = "customer/validateAuthCode";
const GET_QRCODE_DETAILS = "restaurents/getQrCodeDetails";
const GET_RES_BANNER = "customer/getRestaurantBanners";
const ADD_ORDER_DETAIL = "customer/addOrderCarDetails";
const ADD_OFFICEROOM_DETAILS_IN_ORDER = "customer/addOrderOfficeDetails";
const CHECK_ITEM_AVAILABILITY_POS = "customer/checkItemAvailabilityOnPos";
const FEEDBACK_INSTRUCTION = "customer/feedBackInstructionOnRestaurant";
const CHANGE_MODE_OF_PAYMENT = "customer/OrderConfirmedForCashOnArrival";
const UPDATED_ACTIVE_DISCOUNT = "customer/getDiscountDetails";
const GET_MENU_THEME = "restaurents/getMenuBrandingByRestaurentForGuest";
const GET_TIPANDCONTENT = "customer/getTipTypeAndContentType";
const INITIATE_SESSION = "admin/InitiateSession";
const EXECUTE_PAYMENT = "admin/ExecutePayment";
const REGISTER_DOMAIN = "admin/RegisterApplePayDomain";
const Create_Split_Bill = "admin/createSplitBill";
const Get_Split_Bill_Details = "admin/getSplitBillDetails";
const Get_Term_And_Condition = "/customer/getTermsAndConditions";
const checkDetails = "/customer/getCheckDetails";
const paymentStatus = "admin/getPaymentStatus";
const INTERNAL_WEBHOOK = "admin/internalWebHook";
const SmartFeedback = "customer/feedbackByCustomer"



//Getting the restaurent working hours status
export function getRestaurentStatus(payload) {
  return postApi(GET_RESTAURENT_STATUS, payload);
}
export function getRecomendedItemForNotInCart(id) {
  return getApi(GET_RECOMENDED_NOT_IN_CART_ITEMS + "?qr_code_id=" + id);
}
export function getOverAllDiscount(id, qrcode) {
  return getApi(
    GET_TOTAL_DISCOUNT + "?qr_code_id=" + id + "&qr_code_type=" + qrcode
  );
}
export function getUpdateOrderDetails(payload) {
  return postApi(GET_UPDATED_ORDER_DETAILS, payload);
}
export function getItemOfffer(payload) {
  return getApi(ITEM_OFFER_DETAILS + "?item_id=" + payload);
}
//Whats app notification
const WHATS_APP_NOTIFICATION = "/customer/whatsAppNotification";
//Save whats app notification in backend
export function saveWhatsAppNotification(payload) {
  return postApi(WHATS_APP_NOTIFICATION, payload);
}
//Update cart when the free Item will add or discount will added
export function updateCart(payload) {
  return postApi(UPDATE_CART, payload);
}
//Get waiter help
export function getHelpMesaage(payload, order_id) {
  return getApi(GET_WAITER + "?type=" + payload + "&order_id=" + order_id);
}
//Get order details function
export function getOrderDetails(payload) {
  return getApi(GET_ORDER_DETAILS + "?order_id=" + payload);
}
//Chat boat Api
const GET_BOT_HELP = "/customer/addChatBoatMessages";
//Api request for chat boat !Boom coder_0001
export function getHelpFromBot(payload) {
  return postApi(GET_BOT_HELP, payload);
}

//MENU
export function showMenuByID(payload) {
  return getApi(SHOW_MENU_BY_ID + "?menu_id=" + payload);
}

export function showMenu(payload) {
  return getApi(SHOW_MENU + "?qr_code_id=" + payload);
}

export function addToCART(payload) {
  return postApi(ADD_TO_CART, payload);
}

export function viewCart(payload, qrcode) {
  return getApi(
    VIEW_CART + "?qr_code_id=" + payload + "&qr_code_type=" + qrcode
  );
}

export function deleteCartItem(payload) {
  return postApi(DELETE_CART_ITEM, payload);
}

export function add_RECOMMENDED_ITEM_FROM_CART(payload) {
  return postApi(ADD_RECOMMENDED_ITEM_FROM_CART, payload);
}

export function updateCartItemQty(payload) {
  return putApi(CART_ITEM_QTY, payload);
}
export function updateCartRecommendedItemQty(payload) {
  return putApi(CART_RECOMMENDED_ITEM_QTY, payload);
}

export function placeOrder(payload) {
  return postApi(PLACE_ORDER, payload);
}

const paymentCheckSum =
  "https://api.sadadqatar.com/api-v4/userbusinesses/generateChecksum";
export function generateChecksum(payload) {
  return postApi(paymentCheckSum, payload);
}

export function getOrderReceipt(id) {
  return getApi(ORDER_RECEIPT + "?order_id=" + id);
}

export function feedbackSendByCustomer(payload) {
  return postApi(FEEDBACK_BY_CUSTOMER, payload);
}

export function sendEmailReceipts(payload) {
  return postApi(SEND_EMAIL_RECEPIT, payload);
}

export const checkposPayment = (payload) => {
  return postApi(POS_PAYMENT, payload);
};

export const paymentOnSkipcash = (payload) => {
  return postApi(PAYMENT_SKIPCASH, payload);
};

export const syncPOS = (payload) => {
  return postApi(SYNC_POS, payload);
};

export const syncOrderPayemnt = (payload) => {
  return postApi(SYNC_ORDER_PAYEMNT, payload);
};

export const checkAuraCustomerExists = (payload) => {
  return postApi(CHECK_AURACOUSTOMER_EXISTS, payload);
};

export const PosSyncStatus = () => {
  return postApi(POS_SYNC_STATUS);
};

export function getMaterQrCodeDetails(payload) {
  return getApi(MASTER_QRCODE_DETAILS + "?master_qr_code_id=" + payload);
}

export const getSearchData = (payload) => {
  return getApi(`${GET_SEARCH_DETAILS}?${payload}`);
};

export const getUnifiedUserInfo = (payload) => {
  return getApi(`${GET_UNIFIED_USER_INFO}`);
};

export const createAddressInfo = (payload) => {
  return postApi(CREATE_ADDRESS_INFO, payload);
};

export const editAddressInfo = (payload) => {
  return postApi(EDIT_ADDRESS_INFO, payload);
};

export const getAllAddressInfo = (payload) => {
  return getApi(GET_ALL_ADDRESS_INFO);
};
export const debitPoints = (payload) => {
  return postApi(DEBIT_REDEEM_POINTS, payload);
};
export const orderPaymentStatus = (payload) => {
  return postApi(ORDER_PAYMENT_STATUS, payload);
};

export const restaurentQrcode = (id) => {
  return getApi(`${RESTAURENT_QRCODEID}?restaurent_id=${id}`);
};

export const getrestaurentIdByQrcode = (id) => {
  return getApi(`${RESTAURENT_ID}?qr_code_id=${id}`);
};

export const deleteUnifiedUserAddress = (id) => {
  return deleteApi(`${DELETE_USER_ADDRESS}?address_id=${id}`);
};

export const verifyPosCheckStatus = (queryPayload) => {
  return getApi(`${VERIFY_POS_CHECK_STATUS}?${queryPayload}`);
};

export const updateRestaurantWeb = (payload) => {
  return putApi(UPDATE_RESTAURANT_WEB, payload);
};

export const validateAuthCode = (payload) => {
  return postApi(VALIDATE_AUTH_CODE, payload);
};

export const getQrCodeDetails = (queryPayload) => {
  return getApi(`${GET_QRCODE_DETAILS}?${queryPayload}`);
};

export const GetRestaurantBanners = (id) => {
  return getApi(`${GET_RES_BANNER}?restaurent_id=${id}`);
};

export const addOrderCarDetails = (payload) => {
  return postApi(ADD_ORDER_DETAIL, payload);
};

export const addOrderOfficeRoomDetails = (payload) => {
  return postApi(ADD_OFFICEROOM_DETAILS_IN_ORDER, payload);
};

export const checkItemAvailabilityOnPos = (payload) => {
  return postApi(CHECK_ITEM_AVAILABILITY_POS, payload);
};

export const onceArrived = (payload) => {
  return putApi(FEEDBACK_INSTRUCTION, payload);
};

export const ChangeModeOfPayment = (payload) => {
  return putApi(CHANGE_MODE_OF_PAYMENT, payload);
};

export const updatedDiscountActive = (qrcodetype) => {
  return getApi(`${UPDATED_ACTIVE_DISCOUNT}?qrcode=${qrcodetype}`);
};

export const getMenuBrandingByRestaurent = (qrcodetype) => {
  return getApi(`${GET_MENU_THEME}?qr_code_id=${qrcodetype}`);
};

export const getTipTypeAndContentType = (qrcodetype) => {
  return getApi(`${GET_TIPANDCONTENT}?qr_code_type=${qrcodetype}`);
};

export const initiateSession = (payload) => {
  return postApi(INITIATE_SESSION, payload);
};

export const executePayment = (payload) => {
  return postApi(EXECUTE_PAYMENT, payload);
};

export const registerdomainforApplepay = (payload) => {
  return postApi(REGISTER_DOMAIN, payload);
};

export const CreateSplitBillApi = (payload) => {
  return postApi(Create_Split_Bill, payload);
};

export const GetSplitBillDetailsApi = (payload) => {
  return getApi(`${Get_Split_Bill_Details}?order_id=${payload}`);
};

const GET_TRANSACTION_DETAILS = "admin/getTransactionDetails";
export const getTransactionDetails = (payload) => {
  return getApi(`${GET_TRANSACTION_DETAILS}?order_id=${payload}`);
};

export const GetTermAndConditionApi = () => {
  return getApi(Get_Term_And_Condition);
};

export const getCheckDetails = (payload) => {
  return postApi(checkDetails, payload)
}

export const getPaymentStatus = (payload) => {
  return postApi(paymentStatus, payload);
}

export const internalWebHook = (payload) => {
  return postApi(INTERNAL_WEBHOOK, payload);
}

export const SmartFeedbackApi = (payload) => {
  return postApi(SmartFeedback, payload);
}
