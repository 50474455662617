/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Backdrop, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Header } from "../../components";
import BillDetailCart from "../../components/BillDetailsCart/cartBill";
import CartRecommendedItem from "../../components/CartRecommendedItem";
import ViewBasket from "../../components/ViewBasket";
import Offer from "../../assets/offer1.gif";
import moment from "moment";
import {
  checkItemAvailabilityOnPos,
  getOverAllDiscount,
  getRecomendedItemForNotInCart,
  getRestaurentStatus,
  placeOrder,
  syncPOS,
  updateRestaurantWeb,
  updatedDiscountActive,
  validateAuthCode,
  verifyPosCheckStatus,
  viewCart,
} from "../../services/Collection";
import {
  addData,
  addRecomededItemForCart,
  changeFreeItemStatus,
  emptyCartData,
  overAllDiscount,
  updateActiveDiscount,
  UpdateAmount,
} from "../../store/Cart";
import { addOrderDetails } from "../../store/orderPayment";
import CartItem from "./CartItem";
import ToggleSwitch from "../../components/ToggleBtn/ToggleBtn";
import IntlMessage from "../../Utils/IntlMessage";
import { langSuccess } from "../../store/selectlang";
import { VerifyOrder } from "../../components/VerifyOrder/VerifyOrder";
import { updatePaymentMethod } from "../../store/DeliveryModule/DeliverySlice";
import CartPrePayment from "./CartPrePayment";

function Cart() {
  const navigate = useNavigate();
  const [isLoadingButton, setLoadingButton] = useState(false);
  const dispatch = useDispatch();
  // const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  // const currentID = getAlreadyScanedID?.workStation?.slice(-1)?.[0]?.id;

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const isDeliveryEnable = useSelector((state) => state?.deliveryModule);
  const currentID = currentActive?.[0]?.qrcodeid;
  const { id } = useSelector((state) => state?.userAuth?.data);
  const dir = useSelector((state) => state?.selectLang?.direction);
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRecomenedItem, setRecomenedItem] = useState([]);
  const [isCartModified, setCartModified] = useState(false);
  const [verifyOrder, setVerifyOrder] = useState(false);
  const [otp, setOtp] = useState(null);
  const location = useLocation();
  // const OrderID = useSelector(
  //   (state) => state?.orderInformationSlice?.orderDeails?.id
  // );
  const POSisEnable = useSelector((state) => state?.posSlice?.isEnable);
  const selectedType = useSelector((state) => state?.deliveryModule);

  const {
    cartData,
    totalPrice,
    subtotalPrice,
    QrData,
    service_tax,
    vat,
    discount,
    restaurentData,
    cartTotalDiscount,
    isOverallDiscount,
  } = useSelector(({ cartSlice, restaurentSlice }) => ({
    ...cartSlice,
    ...restaurentSlice,
  }));

  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const qrCodeGroup = RestuarantDetails?.QrCodeGroup;
  const customTheme = useSelector((state) => state?.theme?.branding);
  // const IsUnifiedUser = useSelector((state) => state?.userAuth?.data);
  const IsUnifiedUser = useSelector((state) => state?.isUnifiedSlice);
  const UnifiedCustomerDetail = useSelector(
    (state) => state?.isUnifiedSlice?.UnifiedCustomerInfo
  );
  const isPrePayment = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.pre_payment
  );

  const isTakeawayQrCode = useSelector((state) =>
    state?.restaurentSlice?.restaurentData?.QrCodeGroup?.group_type.includes(
      "Takeaway"
    )
  );

  const unifiedOrderType = useSelector(
    (state) => state?.isUnifiedSlice?.orderType
  );

  const IsAuthCodeLinked = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.QrcodesAuthcodes
  );

  //Check restuarent is closed or not
  const handlePreDinein = () => {
    if (qrCodeGroup?.group_type === "Dine In" && isPrePayment) {
      return false;
    } else {
      return true;
    }
  };

  async function viewcart() {
    setLoading(true);
    let qrcode = "";

    if (restaurentData?.QrCodeGroup?.group_type === "Dine In") {
      qrcode = "Dine In";
    } else {
      if (isDeliveryEnable?.orderType === "takeaway") {
        qrcode = "Takeaway";
      } else {
        qrcode = "Delivery";
      }
    }

    let res = await viewCart(currentID, qrcode);
    if (res.status === 200) {
      dispatch(addData(res?.data));
      setLoaded(true);
      setLoading(false);
    } else if (res.status === 404) {
      setLoaded(true);
      setLoading(false);
      dispatch(addData([]));
    } else {
      setLoaded(true);
      setLoading(false);
      dispatch(addData([]));
      // toast.error();
    }
  }
  async function getDiscount() {
    // setLoading(true);
    let qrcode = "";

    if (restaurentData?.QrCodeGroup?.group_type === "Dine In") {
      qrcode = "Dine In";
    } else {
      if (isDeliveryEnable?.orderType === "takeaway") {
        qrcode = "Takeaway";
      } else {
        qrcode = "Delivery";
      }
    }
    console.log(currentID, "cureeeeeeeeeeeeeee");
    let res = await getOverAllDiscount(currentID, qrcode);

    if (res.status === 200) {
      dispatch(overAllDiscount([res?.data]));
    } else {
      dispatch(overAllDiscount([]));
      // toast.error();
    }
  }
  async function getRecomended() {
    let res = await getRecomendedItemForNotInCart(currentID);

    if (res.status === 200) {
      dispatch(addRecomededItemForCart(res?.data));
    } else {
    }
  }

  function isOpen(openingHour, closingHour, currentHour) {
    if (closingHour <= openingHour) {
      // The restaurant closes the next day.
      if (currentHour >= openingHour || currentHour < closingHour) {
        return true;
      }
    } else {
      // The restaurant closes on the same day.
      if (currentHour >= openingHour && currentHour < closingHour) {
        return true;
      }
    }
    return false;
  }

  //Handle payment for user
  const handlePrePayment = () => {
    setVerifyOrder(true);
  };

  const delayPlaceOrder = () => {
    // We are delaying place Order Because POS take few seconds to update
    if(handlePreDinein()){
      setLoading(true);
      setLoadingButton(true);
    }

    // setTimeout(() => {
    handlePlaceIOrder(true);
    // }, 5000);
  };

  const updateResWebForPlaceOrder = async (data) => {
    let obj = {
      order_id: data?.id,
    };

    let res = await updateRestaurantWeb(obj);
  };

  const handlePlaceIOrder = async (paymentMethod, takeaway, modeOfPayment) => {
    console.log(selectedType, "selectedType");
    if(handlePreDinein()){
      setLoading(true);
      setLoadingButton(true);
    }
    let obj = {
      restaurent_id: QrData?.restaurent_id,
      total: totalPrice,
      discount: discount,
      order_type: QrData?.group_type,
      qr_code_id: restaurentData?.id,
      qr_code_group_id: restaurentData?.qr_code_group_id,
      service_tax: service_tax,
      vat: vat,
      sub_total: subtotalPrice,
      currency: "qar",
      is_aura_user: false,
      is_redeem: false,
      loyalty_points: 0,
      pre_payment: paymentMethod,
      mode_of_payment: modeOfPayment,
    };

    if (
      location?.pathname?.includes("/cart") &&
      restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
      selectedType?.orderType === "delivery"
    ) {
      if (modeOfPayment == 8) {
        obj.order_type = "Delivery";
        obj.mode_of_payment = 9;
        obj.checkingCase = "COD";
      } else {
        delete obj.mode_of_payment;
        obj.order_type = "Delivery";
        obj.checkingCase = "Online";
      }

      let minOrderAmount =
        RestuarantDetails?.Menu?.Restaurent?.min_order_amount_for_delivery;
      console.log(minOrderAmount, totalPrice, "TESTINGGGGG");
      if (minOrderAmount > totalPrice) {
        setLoading(false);
        setLoadingButton(false);
        toast.error(
          `Minimum order amount for delivery is ${minOrderAmount}, please add more items to cart`,
          { theme: "colored" }
        );
        return;
      }
    }

    const currentHour = moment().format("HH:mm:ss");
    const payload = {
      restaurent_id: QrData?.restaurent_id,
    };
    const response = await getRestaurentStatus(payload);
    const { close_time, open_time, status } = response?.data;

    const { disable_order } = response?.data?.Restaurent;

    //Checking if resturent is accepting orders
    if (disable_order) {
      let alert = {
        message: "Currently restaurent is not accepting orders!",
      };
      navigate("/closed", {
        state: { message: alert },
      });

      return;
    }

    if (isOpen(open_time, close_time, currentHour)) {
      console.log("The restaurant is open");
    } else {
      console.log("The restaurant is closed");
      let alert = {
        message: "Currently restaurent is not accepting orders!",
      };
      navigate("/closed", {
        state: { message: alert },
      });
      return;
    }
    if (status === "1") {
      let alert = {
        message: `Restaurent is closed`,
      };
      navigate("/closed", {
        state: { message: alert },
      });

      return;
    }

    ///****************If restaurent is accepting order ************/
    //If all condition is satisfied only and only then plece order

    const res = await placeOrder(obj);

    if (res.status === 200) {
      //Removing cart data
      if (handlePreDinein()) {
        dispatch(emptyCartData());
      }
      dispatch(addOrderDetails(res?.data));
      updateResWebForPlaceOrder(res?.data);
      //Show the success message to user
      if (paymentMethod) {
        console.log("paymentMethod");
      } else if (takeaway) {
        console.log("takeaway");
      } else {
        toast.success("Order has been placed successfully");
      }
      setLoading(false);

      //navigating to payment page
      // if (takeaway && modeOfPayment) {
      //   navigate(`/${currentID}/user-feedback`);
      // } else {
      if (qrCodeGroup?.group_type === "Dine In") {
        if (paymentMethod) {
          // navigate(`/${currentID}/pre-payment`);
        } else {
          navigate(`/${currentActive?.[0]?.qrcodeid}/addfoodlist`);
        }
      } else {
        if (
          location?.pathname?.includes("/cart") &&
          restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
          selectedType?.orderType === "delivery"
        ) {
          navigate(`/${currentID}/select-location`);
        } else {
          if (paymentMethod || takeaway) {
            navigate(`/${currentID}/pre-payment`);
          } else {
            navigate(`/${currentID}/payment`);
          }
        }
      }

      // }
    } else {
      dispatch(changeFreeItemStatus(false));
      setLoadingButton(false);
      toast.error(res.response.data.message);
      setLoadingButton(false);
      setLoading(false);
    }
  };
  //Handle response
  const handelCartModification = () => {
    setLoaded(false);
  };

  const CalculatingAllDiscount = () => {
    cartTotalDiscount &&
      cartTotalDiscount?.length > 0 &&
      cartTotalDiscount?.map((udx) => {
        console.log(udx, "udDddddsdsd", subtotalPrice);
        if (udx?.minimum_order_amount) {
          const vat_percent = restaurentData?.Menu?.Restaurent?.vat;
          const service_percent =
            restaurentData?.Menu?.Restaurent?.service_charge;
          const vateno = parseFloat(
            ((subtotalPrice * vat_percent) / 100).toFixed(2)
          );

          const service_charge = parseFloat(
            ((subtotalPrice * service_percent) / 100).toFixed(2)
          );

          if (udx?.minimum_order_amount <= subtotalPrice) {
            let amount = parseFloat(subtotalPrice).toFixed(2);
            let discount = parseInt(udx.discount);
            let discount_amount = (amount * discount) / 100;
            let discount_value = parseFloat(discount_amount).toFixed(2);
            let amount_after_discount = amount - discount_value;

            let temp_totalPrice = parseFloat(
              amount_after_discount + vateno + service_charge
            ).toFixed(2);

            let payload = {
              type: "1",
              totalPrice: temp_totalPrice,
              discount: discount_value,
            };
            dispatch(UpdateAmount(payload));
          } else if (udx?.minimum_order_amount > subtotalPrice) {
            let discount_value = 0;
            let temp_totalPrice = parseFloat(
              subtotalPrice + vateno + service_charge
            ).toFixed(2);

            let payload = {
              type: "0",
              totalPrice: temp_totalPrice,
              discount: discount_value,
            };

            dispatch(UpdateAmount(payload));
          }
          //Here if condition exceed the limit
          else if (udx?.maximum_order_amount < subtotalPrice) {
            let discount_value = 0;
            let temp_totalPrice = parseFloat(
              subtotalPrice + vateno + service_charge
            ).toFixed(2);

            let payload = {
              type: "0",
              totalPrice: temp_totalPrice,
              discount: discount_value,
            };
            dispatch(UpdateAmount(payload));
          }
        }
      });
  };

  const inputValues = (ele) => {
    setOtp(ele);
  };

  const [authCodeLoading, setAuthCodeLoading] = useState(false);
  const handleAuthCodeSubmit = async () => {
    if (otp != null && otp.length == 5) {
      let obj = {
        qr_code_id: restaurentData?.id,
        auth_code: otp,
      };
      setAuthCodeLoading(true);
      let res = await validateAuthCode(obj);
      if (res?.status === 200) {
        setAuthCodeLoading(false);
        handlePlaceIOrder(false);
      } else {
        setAuthCodeLoading(false);
        toast.error(res?.response?.data?.message || res?.message || res?.error);
      }
    } else {
      toast.error("Please enter valid otp", { theme: "colored" });
    }
  };
  const ViewTextCard = () => {
    if (isPrePayment || isTakeawayQrCode) {
      // return "Process to pay";
      return <IntlMessage id="cart.button.payOnline" />;
    } else {
      return <IntlMessage id="cart.button.placeorder" />;
    }
  };

  const handlePaymentMethod = (type) => {
    if (
      location?.pathname?.includes("/cart") &&
      restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
      selectedType?.orderType === "delivery"
    ) {
      dispatch(updatePaymentMethod(1));
    }
  };

  const syncposAndsendValuestoDB = async (modeOfPayment) => {
    if(handlePreDinein()){
      setLoading(true);
      setLoadingButton(true);
    }

    let req = {
      restaurent_id: RestuarantDetails?.restaurent_id,
      qr_code_id: RestuarantDetails?.id,
    };
    let res = await syncPOS(req);
    if (res?.status === 200) {
      handleAction(modeOfPayment);
    }
    if (res?.response?.status === 401) {
      toast.error(res.response.data.message, { theme: "colored" });
    }
  };

  const handleAction = (modeOfPayment) => {
    console.log(modeOfPayment, " modeOfPayment");
    if (isPrePayment) {
      // Order Placed ----------------
      delayPlaceOrder();
    } else if (isTakeawayQrCode && modeOfPayment) {
      handlePlaceIOrder(false, true, modeOfPayment);
    } else if (isTakeawayQrCode) {
      handlePlaceIOrder(false, true);
    } else if (IsAuthCodeLinked.length === 0) {
      handlePlaceIOrder(false);
    } else {
      // Open Auth code ----------------
      setLoading(false);
      setLoadingButton(false);
      handlePrePayment();
    }
  };

  // const checkQuantityAvaiable = (el, data) => {
  //   console.log(el, data, "checkkkkkkk");

  //   let currentItem = cartData?.filter(
  //     (item) => item?.Item?.pos_item_id === el?.pos_item_id
  //   );

  //   // let allAvaiable = data?.every(
  //   //   (item) =>
  //   //     !item?.item_out_of_menu &&
  //   //     item?.availability_status &&
  //   //     Number(item?.total_quantity) > 0
  //   // );

  //   function isAllItemsAvaiable(data) {
  //     let flag = 0;
  //     for (let i = 0; i < data.length; i++) {
  //       if (!data[i]?.item_out_of_menu) {
  //         if (data[i]?.availability_status) {
  //           for (let j = 0; j < cartData.length; j++) {
  //             if (data[i]?.pos_item_id === cartData[j]?.Item?.pos_item_id) {
  //               if (
  //                 Number(data[i].total_quantity) >= Number(cartData[j]?.qty)
  //               ) {
  //                 flag++;
  //               }
  //             }
  //           }
  //         }
  //       } else {
  //         return false;
  //       }
  //     }

  //     if (data.length === flag) return true;
  //   }

  //   if (isAllItemsAvaiable(data)) {
  //     if (Number(el.total_quantity) >= Number(currentItem?.[0]?.qty)) {
  //       // calling place order API
  //       syncposAndsendValuestoDB();
  //     } else {
  //       toast.error(
  //         `Quantity available is only ${el?.total_quantity}, please reduce the quantity of ${el?.pos_item_name}`,
  //         {
  //           theme: "colored",
  //         }
  //       );
  //     }
  //   } else {
  //     if (Number(el.total_quantity) > 0) {
  //       if (Number(el.total_quantity) < Number(currentItem?.[0]?.qty)) {
  //         toast.error(
  //           `Quantity available is only ${el?.total_quantity}, please reduce the quantity of ${el?.pos_item_name}`,
  //           {
  //             theme: "colored",
  //           }
  //         );
  //       }
  //     } else {
  //       toast.error(
  //         `Item ${el?.pos_item_name} not available, please remove it from the cart`,
  //         {
  //           theme: "colored",
  //         }
  //       );
  //     }
  //   }
  // };

  const ToastErrorMessage = (type, ele) => {
    if (type === "notAvailable") {
      return toast.error(
        `Item ${ele?.Item?.name} not available, please remove it from the cart`,
        {
          theme: "colored",
        }
      );
    } else if (type === "quanity") {
      return toast.error(
        `Quantity available is only ${ele?.Item?.item_availability_quantity}, please reduce the quantity of ${ele?.Item?.name}`,
        {
          theme: "colored",
        }
      );
    }
  };
  //test comment

  const checkItemAvaiableInAqlut = (modeOfPayment) => {
    handlePaymentMethod(1);
    console.log(modeOfPayment, "modeeeeeeOfpayyyyyy");
    let isCashOnArrival;
    if (modeOfPayment === 8) isCashOnArrival = 8;
    let flag = 0;
    cartData?.map((ele) => {
      if (ele?.Item?.item_availability_quantity === null) {
        // If item avaiability is equal to null
        flag++;
      } else if (ele?.Item?.item_availability_quantity) {
        // If item avaiability is greater than 0
        if (ele?.Item?.item_availability_quantity >= ele?.qty) {
          flag++;
        } else {
          ToastErrorMessage("quanity", ele);
        }
      } else {
        ToastErrorMessage("notAvailable", ele);
      }
    });

    if (cartData?.length === flag) {
      if (POSisEnable) {
        syncposAndsendValuestoDB(isCashOnArrival);
      } else {
        handleAction(isCashOnArrival);
      }
    }

    // POSisEnable ? isItemAvaiableinPOS : handleAction
  };

  const checkItemAvailabilityInPos = async (mode_of_payment) => {
    handlePaymentMethod(1);
    setLoading(true);
    setLoadingButton(true);
    syncposAndsendValuestoDB(mode_of_payment);

    // let isErrorExist = 0;
    // let reqpayload = [];

    // if (cartData.length > 0) {
    //   reqpayload = cartData.map((el) => el?.Item?.pos_item_id);
    // }

    // let res = await checkItemAvailabilityOnPos(reqpayload);
    // if (res.status === 200) {
    //   console.log(res, "resssssssssss status");
    //   let checkedOutOfMenu = res.data.map((el) =>
    //     el?.item_out_of_menu
    //       ? (toast.error(
    //         `Item ${el?.pos_item_name} not available, please remove it from the cart`,
    //         { theme: "colored" }
    //       ),
    //         isErrorExist++,
    //         setLoading(false),
    //         setLoadingButton(false))
    //       : { isItemAvaiable: false, ...el }
    //   );

    //   let checkedAvailabityStatus = checkedOutOfMenu?.map((el) =>
    //     el.constructor.name === "Object"
    //       ? el?.availability_status
    //         ? { ...el, isItemAvaiable: false }
    //         : { ...el, isItemAvaiable: true }
    //       : null
    //   );

    //   console.log(checkedAvailabityStatus, "checkedAvailabityStatus");

    //   let checkedSpecialCount = checkedAvailabityStatus?.map((el) =>
    //     el
    //       ? !el?.isItemAvaiable &&
    //         Number(el?.special_count) < Number(el?.env_availability_variable)
    //         ? (toast.error(
    //           `Item ${el?.pos_item_name} not available, please remove it from the cart`,
    //           { theme: "colored" }
    //         ),
    //           isErrorExist++,
    //           setLoading(false),
    //           setLoadingButton(false))
    //         : el
    //       : null
    //   );
    //   console.log(checkedSpecialCount, "checkedSpecialCount");

    //   let finalUpdatedArray = checkedSpecialCount?.filter(
    //     (el) =>
    //       typeof el === "object" && !Array.isArray(el) && el !== null && el
    //   );

    //   console.log(finalUpdatedArray, "final updated array");

    //   let checkedCartQtyWithPOSQty = finalUpdatedArray?.map((el) =>
    //     el?.isItemAvaiable ? el : checkCartAndPosQuantity(el, finalUpdatedArray)
    //   );

    //   let ApiCalling = checkedCartQtyWithPOSQty.every(
    //     (el) => el?.isItemAvaiable
    //   );

    //   if (ApiCalling && isErrorExist === 0)
    //     syncposAndsendValuestoDB(mode_of_payment);

    //   console.log(ApiCalling, isErrorExist, "IsError");

    //   console.log(checkedCartQtyWithPOSQty, "aaaaaaaaaaaaaaaaaa");
    // } else {
    //   setLoading(false);
    //   setLoadingButton(false);
    //   toast.error(res.error || res.response.data.message, {
    //     theme: "colored",
    //   });
    // }
  };

  const checkCartAndPosQuantity = (el, data) => {
    console.log(el, data, "checkCartAndPosQuantity");
    let tempObject;
    cartData?.filter(
      (item) =>
        item?.Item?.pos_item_id === el?.pos_item_id &&
        (tempObject = { ...el, qty: item?.qty })
    );

    console.log(tempObject, "obejctttttttttt");

    if (Number(tempObject?.qty) <= Number(tempObject?.special_count)) {
      return { ...tempObject, isItemAvaiable: true, sending: "below function" };
    } else {
      toast.error(
        `Quantity available is only ${el?.special_count}, please reduce the quantity of ${el?.pos_item_name}`,
        // `Item ${el?.pos_item_name} not available, please remove it from the cart`,
        {
          theme: "colored",
        }
      );
      return {
        ...tempObject,
        isItemAvaiable: false,
        sending: "below function",
      };
    }
  };

  const handleCashOnArrival = () => {
    if (POSisEnable) {
      // checkItemAvailabilityInPos(8);
      syncposAndsendValuestoDB(8);
    } else {
      checkItemAvaiableInAqlut(8);
    }
  };

  const MyCartItemData = useCallback(() => {
    return (
      <>
        {cartData?.map((el, key) => (
          <CartItem
            isCartModified={handelCartModification}
            reloadData={() => setLoaded(false)}
            key={key}
            el={el}
            indicator="i"
            cartId={el.id}
          />
        ))}
      </>
    );
  }, [cartData]);

  const updateDiscount = async () => {
    let qrcode = "";

    if (RestuarantDetails?.QrCodeGroup?.group_type === "Dine In") {
      qrcode = "Dine In";
    } else {
      if (isDeliveryEnable?.orderType === "takeaway") {
        qrcode = "Takeaway";
      } else {
        qrcode = "Delivery";
      }
    }

    let res = await updatedDiscountActive(qrcode);
    if (res.status === 200) {
      dispatch(updateActiveDiscount(res.data));
    }
  };

  useEffect(() => {
    if (!loaded) {
      viewcart();
      getDiscount();
      getRecomended();
      updateDiscount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  //Getiing the total Amount discount when condition satisfied
  useMemo(() => {
    // eslint-disable-next-line array-callback-return
    cartData?.map((imx) => {
      if (imx.discount === 0 && imx?.free_item === false) {
        // getDiscount();
      } else if (imx.discount > 0 && imx?.free_item === false) {
        dispatch(overAllDiscount([]));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, isCartModified]);

  useEffect(() => {
    CalculatingAllDiscount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtotalPrice, isCartModified]);

  return (
    <>
      {verifyOrder && (
        <VerifyOrder
          open={verifyOrder}
          handleClose={() => setVerifyOrder(false)}
          otp={otp}
          inputValues={inputValues}
          numInputs={5}
          handleAuthCodeSubmit={handleAuthCodeSubmit}
          loading={authCodeLoading}
        />
      )}
      {isLoading ? (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <LoaderWithAnimation
            style={{ background: customTheme?.background_color }}
          >
            <CircularProgress sx={{ color: customTheme?.theme_color }} />
            <p style={{ color: customTheme?.theme_color, marginTop: "5px" }}>
              Processing...
            </p>
          </LoaderWithAnimation>
        </Backdrop>
      ) : (
        <>
          <Header />
          {cartTotalDiscount?.length > 0 &&
            !isOverallDiscount &&
            cartData.length > 0 &&
            cartTotalDiscount?.map(
              (udx) =>
                udx.title && (
                  <OfferContainer dir={dir}>
                    <div className="offerContainer">
                      <img className="OfferImage" src={Offer} alt="offer" />
                      {/* <p className="freeItem">{`${udx.title} minimum order amount: ${udx.minimum_order_amount} and maximum order amount: ${udx.maximum_order_amount} to avail ${udx.discount}% discount`}</p> */}
                      <p className="freeItem">
                        {" "}
                        <IntlMessage id="OfferMessage.AvailSpecialOffer" />{" "}
                        {udx.discount}%{" "}
                        <IntlMessage id="OfferMessage.OffMinPutchase" /> QAR
                        {udx.minimum_order_amount}.
                      </p>
                    </div>
                  </OfferContainer>
                )
            )}

          {cartData?.length === 0 && (
            <NoItemInCart
              dir={dir}
              style={{
                background: customTheme?.background_color,
                color: customTheme?.header_color,
              }}
            >
              <NavLink className="link" to={`/${currentID}/addfoodlist`}>
                No Item in cart, Go back.
              </NavLink>
            </NoItemInCart>
          )}

          {cartData?.length > 0 && (
            <CartParent
              dir={dir}
              style={{ background: customTheme?.background_color }}
            >
              <>
                <h4
                  style={{ color: customTheme?.header_color }}
                  className="orderDHeading"
                >
                  <IntlMessage id="cart.yourorderdetails.title" />
                </h4>

                <div className="orderDetail">
                  <MyCartItemData />

                  {cartData?.map((el) => {
                    return el?.AddToCartRecommendedItems?.map((rItem, key) => {
                      return (
                        <CartItem
                          cartId={el.id}
                          key={key}
                          el={rItem}
                          indicator="r"
                          currency={el.currency}
                        />
                      );
                    });
                  })}
                </div>

                <BillDetailAndRecommended margin={!handlePreDinein()}>
                  {IsUnifiedUser?.is_aura_user && (
                    <LoyalityPoints>
                      <div className="points-content">
                        <h4>
                          <IntlMessage id="cart.cartRedeemedTitle" />
                        </h4>
                        <h5>
                          {" "}
                          <IntlMessage id="cart.LoyalityAvailablePoints" />
                        </h5>
                      </div>
                      <div className="toggle-Container">
                        {UnifiedCustomerDetail?.totalPoints > 0 ? (
                          <ToggleSwitch name="RedeemPoints" />
                        ) : (
                          <ToggleSwitch name="NotRedeemPoints" />
                        )}
                        <h3>
                          {UnifiedCustomerDetail?.totalPoints}{" "}
                          <IntlMessage id="unified.loyality.totalPoints" />
                        </h3>
                      </div>
                    </LoyalityPoints>
                  )}

                  <BillDetailCart
                    loyalityPoints={UnifiedCustomerDetail?.totalPoints}
                    type={"OrderDetail"}
                    totalPayable={totalPrice}
                    subtotalPrice={subtotalPrice}
                    tax={service_tax + vat}
                    discount={discount}
                  />

                  {cartData?.length > 0 && (
                    <CartRecommendedItem
                      recomenedItem={isRecomenedItem}
                      reloadData={() => setLoaded(false)}
                    />
                  )}
                </BillDetailAndRecommended>

                {handlePreDinein() && cartData?.length > 0 && (
                  <ViewBasket
                    style={{ position: "fixed" }}
                    isLoadingButton={isLoadingButton}
                    ViewText={ViewTextCard()}
                    handleViewAction={
                      POSisEnable
                        ? syncposAndsendValuestoDB
                        : checkItemAvaiableInAqlut
                    }
                    isCashOnArrival={location?.pathname?.includes("/cart")}
                    handleCashOnArrival={handleCashOnArrival}
                  />
                )}

                {!handlePreDinein() && (
                  <CartPrePayment
                    handleViewAction={
                      POSisEnable
                        ? syncposAndsendValuestoDB
                        : checkItemAvaiableInAqlut
                    }
                  />
                )}
              </>
            </CartParent>
          )}
        </>
      )}
    </>
  );
}

export default Cart;

const NoItemInCart = styled.div`
  width: 100%;
  height: 100vh;
  padding: 40px 20px;
  a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: inherit;
  }
`;

const LoaderWithAnimation = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;

  p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212bd;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }

  .aminationLogo {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
`;

const CartParent = styled.div`
  background: #ffffff;
  ${"" /* padding: 10px 20px; */}
  ${"" /* padding-bottom: 40px; */}
  .FoodDetailsCntainers {
    width: 100% !important;
  }

  .bill-details {
    margin: 20px 0px;
  }

  .orderDHeading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #242424;
    padding: 0px 15px;
    margin: 10px 0px 19px;
  }

  .orderDetail {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0px 15px;
    @media only screen and (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
const OfferContainer = styled.div`
  padding: 10px 20px;

  div {
    display: flex;
    align-items: center;
  }
  .OfferImage {
    width: 60px;
    margin: auto;
  }
  .freeItem {
    margin-left: 10px;
  }
`;
const BillDetailAndRecommended = styled.div`
  margin-top: 20px;
  margin-bottom: ${({ margin }) =>
    margin ? "0rem !important" : "16rem !important"};
  padding: 0px 15px;
`;

const LoyalityPoints = styled.div`
  background: #f8f8fb;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  .points-content {
    h4 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #242424;
      padding-top: 6px;
    }
    h5 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding-top: 6px;
      color: #242424;
    }
  }

  .toggle-Container {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: end;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      padding-top: 5px;
      color: #fc2b6e;
    }
  }
`;
