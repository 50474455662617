import React, { useEffect, useRef, useState } from "react";
import { Header, RecommendedItem, AllergiesItem } from "../../components";
import "../../style/AddFoodList.scss";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useSelector, useDispatch } from "react-redux";
import Item from "./Item";
import AddItemDrawer from "./AddItemDrawer";
import { toggleItem } from "../../store/RecommentedItem";
import { addItem, removeItem } from "../../store/selectItem";
import { Backdrop, CircularProgress } from "@mui/material";

import ViewBasket from "../../components/ViewBasket";
import { useNavigate } from "react-router-dom";
import { addRestaurentData } from "../../store/restaurenDetail";
import axios from "axios";
import {
  addData,
  addQrData,
  overAllDiscount,
  updateActiveDiscount,
} from "../../store/Cart";
import {
  authSucess,
  checkQrcodeIsMaster,
  InitialScan,
  loginWithMasterQrCode,
  Logout,
} from "../../store/userLoginReducer";
import { restartChat } from "../../store/ChatHistory";
import { toast } from "react-toastify";
import {
  checkItemAvailabilityOnPos,
  getCheckDetails,
  getOrderDetails,
  getOverAllDiscount,
  getSearchData,
  getTipTypeAndContentType,
  getUpdateOrderDetails,
  PosSyncStatus,
  syncOrderPayemnt,
  syncPOS,
  updatedDiscountActive,
  viewCart,
} from "../../services/Collection";
import styled from "styled-components";
import { SearchIcon } from "../../Utils/Images";
import AllMenuListing from "./AllMenuListing";
import IntlMessage from "../../Utils/IntlMessage";
import { PosIsReachable, posStatus } from "../../store/posStatus";
import {
  addOrderDetails,
  multiOrderDeatils,
  splitBill,
  updatedOrderFromPos,
  wayToPayBillType,
} from "../../store/orderPayment";
import { checkOrderIsFromPOS } from "../../Utils/CommanFunctions";
import SwitchAccount from "../guest/SwitchAccount";
import AdvertisementBanner from "../../components/AdvertisementBanner.js/AdvertisementBanner";
import Tipheader from "../../components/header/Tipheader";
import {
  DoubleArrowRightIcon,
  LaunchPayBillIcon
} from "../../Utils/Icons";
import { PayBillButton } from "../guest/GuestMenuListing";
import MainSection, { BlackLayer } from "../MainSection/MainSection";
import { useIntl } from "react-intl";

export default function AddFoodList() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerSection = process.env.REACT_APP_CONTAINER_SECTION;

  const [message, setmessage] = useState("");
  const POSisEnable = useSelector((state) => state?.posSlice?.isEnable);

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const dispatch = useDispatch();
  const intl = useIntl();
  const [model, setModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [allegiesModel, setallegiesModel] = useState(false);
  const [menu, setMenu] = useState([]);
  const [inputNone, updateInputNode] = useState("none");
  // const [menu] = useState([restaurentData?.Menu?.Sections]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const { showItems, cartData } = useSelector(
    ({ selectLang, menuSlice, recommendedItemSlice, cartSlice }) => ({
      ...menuSlice,
      ...recommendedItemSlice,
      ...cartSlice,
    })
  );
  const CustomerID = useSelector((state) => state?.userAuth?.data);
  const token = useSelector((state) => state?.userAuth?.data?.token);
  const IsUnifiedUser = useSelector((state) => state?.isUnifiedSlice);
  const Direction = useSelector((state) => state?.selectLang?.direction);
  const GetAllOrders = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Orders
  );
  const isPrePayment = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.pre_payment
  );
  const [alertMessage, setAlertMessage] = useState(false);
  const isDeliveryEnable = useSelector((state) => state?.deliveryModule);
  const customTheme = useSelector((state) => state?.theme?.branding);
  const qrCodeGroup = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.QrCodeGroup
  );
  const OrderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const isposreachable = useSelector(
    (state) => state?.posSlice?.posIsReachable
  );
  const isPaymentDisable = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.payment_enable
  );
  // const POSisEnable = useSelector((state) => state?.posSlice?.isEnable);
  const isAuthenticated = useSelector((state) => state?.userAuth?.data?.token);

  const [loaderPayBill, setLoaderPayBill] = useState(true);

  const categoryListRef = useRef(null);

  const handleScroll = (e) => {
    const foodList = e.target;
    const foodCategories = Array.from(
      foodList.getElementsByClassName("food-category")
    );

    foodCategories.forEach((category, index) => {
      const rect = category.getBoundingClientRect();
      const categoryList = categoryListRef.current;
      const categoryItems = categoryList.children;

      if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
        categoryItems[index].scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      }
    });
  };

  const toggleDrawer = (item) => {
    let checking = checkOrderIsFromPOS(GetAllOrders);
    if (isPrePayment) {
      if (checking) {
        performingActiononAddIcons(item);
      }
    } else {
      performingActiononAddIcons(item);
    }
  };

  const checkingPosStatus = () => {
    // isposreachable && !isPaymentDisable
    if (isPaymentDisable) {
      return false;
    } else {
      if (POSisEnable) {
        if (isposreachable) {
          return true;
        }
        return false;
      } else {
        if (isAuthenticated) {
          return true;
        }
        return false;
      }
    }
  };

  const performingActiononAddIcons = (item) => {
    // if (item?.available_for_ordering) {
    dispatch(addItem(item));
    setSelectedItem(item);
    setModel(true);
    // } else {
    //   setAlertMessage(true);
    // }
  };

  const refs = menu?.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const open = Boolean(anchorEl);
  useEffect(() => {}, [anchorEl]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClick = (id, key) => {
    if (id) {
      refs[id]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    setAnchorEl(false);
  };

  //allergies event hook is here...
  const toggleAllegiesItem = (event) => {
    setallegiesModel(true);
  };
  const special_notes = (event) => {
    if (inputNone === "none") {
      updateInputNode("block");
    } else {
      updateInputNode("none");
    }
  };

  const id = open ? "simple-popper" : undefined;

  const ViewCart = () => {
    if (RestuarantDetails?.Menu?.Restaurent?.disable_order) {
      setAlertMessage(true);
    } else {
      navigate(`/${currentActive?.[0]?.qrcodeid}/cart`);
    }
  };

  const checkingItemsOnly = (data) => {
    if (data?.Items?.length > 0 && data?.Sections?.length === 0) {
      return data;
    }
    return false;
  };

  const checkingSectionsOnly = (data) => {
    if (data?.Sections?.length > 0 && data?.Items?.length === 0) {
      let tempSectionArray = [];
      for (let j = 0; j < data?.Sections.length; j++) {
        if (data?.Sections[j]?.Items?.length > 0) {
          tempSectionArray.push(data?.Sections[j]);
        }
      }
      if (tempSectionArray?.length > 0) {
        return tempSectionArray;
      }
    }
  };

  const checkingSectionsItmemsBoth = (data) => {
    if (data?.Sections?.length > 0 && data?.Items?.length > 0) {
      let tempSectionArray = [];
      for (let j = 0; j < data?.Sections.length; j++) {
        if (data?.Sections[j]?.Items?.length > 0) {
          tempSectionArray.push(data?.Sections[j]);
        }
      }
      if (tempSectionArray?.length > 0) {
        return tempSectionArray;
      }
    }
  };

  const sectionDataManupilate = (payload) => {
    let tempArray = [];
    for (let i = 0; i < payload.length; i++) {
      let resultItems = checkingItemsOnly(payload?.[i]);
      if (resultItems) tempArray.push(payload?.[i]);
      let resultSections = checkingSectionsOnly(payload?.[i]);
      if (resultSections)
        tempArray.push({ ...payload[i], Sections: resultSections });
      let resultBoth = checkingSectionsItmemsBoth(payload?.[i]);
      if (resultBoth) tempArray.push({ ...payload[i], Sections: resultBoth });
    }
    return tempArray;
  };

  // const sectionDataManupilate = (payload) => {
  //   let tempArray = [];
  //   for (let i = 0; i < payload.length; i++) {
  //       if (payload?.[i]?.Items?.length > 0) {
  //         tempArray.push(payload?.[i]);
  //       }
  //       if (payload?.[i]?.Sections?.length > 0) {
  //         payload?.[i]?.Sections?.every(
  //           (el) => el?.Items?.length > 0 && tempArray.push(payload?.[i])
  //         );
  //     }
  //   }
  //   return tempArray;
  // };

  // calling restaurent api
  async function getMenuItem(id) {
    setLoading(true);
    const EndPoint =
      process.env.REACT_APP_BASEURL + process.env.REACT_APP_API_VERSION;

    const baseURL = `${EndPoint}restaurents/getQrCodeDetails/`;
    var CustomerIDURL = `${baseURL}?qr_code_id=${id}&customer_id=${CustomerID?.id}&is_aura_user=${IsUnifiedUser?.is_aura_user}`;
    var url = `${baseURL}?qr_code_id=${id}`;

    setmessage("");
    try {
      const res = await axios({
        method: "get",
        url: CustomerID?.id ? CustomerIDURL : url,
        headers: { "Content-Type": "multipart/form-data" },
      });

      //handle success
      if (res.status === 200) {
        let resulut = sectionDataManupilate(res?.data?.data?.Menu?.Sections);
        setMenu(resulut);
        dispatch(addRestaurentData(res?.data?.data));
        dispatch(addQrData(res?.data?.data?.QrCodeGroup));
        dispatch(toggleItem(false));

        dispatch(multiOrderDeatils(res?.data?.data?.Orders));
        dispatch(removeItem());
        dispatch(InitialScan(true));
        let data = {
          pos_integration_status:
            res?.data?.data?.QrCodeGroup?.Restaurent?.pos_integration_status,
        };
        localStorage.setItem("isPOSEnable", data?.pos_integration_status);
        dispatch(posStatus(data));
        if (res?.data?.data?.QrCodeGroup?.group_type === "Dine In") {
          getTipAndContent(res?.data?.data?.QrCodeGroup?.group_type);
          const total =
            res?.data?.data?.Orders?.[0]?.total -
            (res?.data?.data?.Orders?.[0]?.partial_payment_amount +
              res?.data?.data?.Orders?.[0]?.specific_payment_amount);
          dispatch(
            addOrderDetails({ ...res?.data?.data?.Orders?.[0], total: total })
          );
        } else {
          dispatch(addOrderDetails(res?.data?.data?.Orders?.[0]));
          if (isDeliveryEnable?.orderType === "takeaway") {
            getTipAndContent("Takeaway");
          } else {
            getTipAndContent("Delivery");
          }
        }

        if (!res?.data?.data?.is_master) {
          updateFooter();
        }

        if (res?.data?.data?.is_master) {
          navigate(`/?qrcodeid=${id}`);
          dispatch(checkQrcodeIsMaster(res?.data?.data?.is_master));
          dispatch(authSucess([]));
        }
      } else if (res?.status === 400) {
        setLoading(false);
        let mesg =
          "Something went wrong! Make sure you have internet connectivity.";
        setmessage(mesg);
        toast.error(res.message);
      }
    } catch (response_1) {
      let msg =
        "Menu or  sections not found for Restaurents please try with another QR CODE";
      setmessage(msg);
      setLoading(false);
    }
  }

  const [searchData, setSearchData] = useState([]);

  const manulipateSearchData = (payload) => {
    let searchedItem = [];
    payload?.map((el) => searchedItem?.push(...el?.Items));
    return searchedItem;
  };

  const searchRef = useRef(null);

  const searchDebouning = (value) => {
    if (searchRef?.current) {
      clearTimeout(searchRef?.current);
    }
    searchRef.current = setTimeout(() => getMenuItemBySearch(value), 500);
  };

  const getMenuItemBySearch = async (value) => {
    if (value.length > 0) {
      setSearchLoading(true);
      let params = new URLSearchParams();
      params.append("search", value);
      params.append("restaurent_id", currentActive?.[0]?.restaurentid);

      let res = await getSearchData(params.toString());
      if (res.status === 200) {
        let result = manulipateSearchData(res?.data?.[0]?.Sections);
        setSearchData(result);
        // setMenu(res?.data?.[0]?.Sections);
        setSearchLoading(false);
      } else {
        toast.error(res.message);
        setSearchLoading(false);
      }
    } else {
      setSearchData([]);
      getMenuItem(currentActive?.[0]?.qrcodeid);
    }
  };

  const updateFooter = async () => {
    let qrcode = "";

    if (RestuarantDetails?.QrCodeGroup?.group_type === "Dine In") {
      qrcode = "Dine In";
    } else {
      if (isDeliveryEnable?.orderType === "takeaway") {
        qrcode = "Takeaway";
      } else {
        qrcode = "Delivery";
      }
    }

    if (token !== undefined) {
      let res = await viewCart(currentActive?.[0]?.qrcodeid, qrcode);
      if (res.status === 200) {
        dispatch(addData(res?.data));
        setLoading(false);
      } else {
        dispatch(addData([]));
        setLoading(false);
      }
    }
  };

  const [tipContent, setTipContent] = useState({
    tip_type: "",
    tip_content_english: "",
    tip_content_arabic: "",
  });

  const getTipAndContent = async (qrcodetype) => {
    let res = await getTipTypeAndContentType(qrcodetype);
    if (res.status === 200) {
      let tempObject = {
        tip_type: res?.data?.tip_type,
        tip_content_english: res?.data?.tip_content_english?.replace("\n", ""),
        tip_content_arabic: res?.data?.tip_content_arabic?.replace("\n", " "),
      };
      setTipContent(tempObject);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getOrderDetailsData = async (order) => {
    let res = await getOrderDetails(order.id);
    if (res.status === 200) {
      let updatedArr = [];

      for (let index = 0; index < res?.data?.OrderItems?.length; index++) {
        updatedArr.push({
          ...res?.data?.OrderItems[index],
          ischecked: false,
        });
      }
      const total =
        res?.data?.total -
        (res?.data?.partial_payment_amount +
          res?.data?.specific_payment_amount);

      let response = { ...res?.data, total, OrderItems: updatedArr };

      dispatch(updatedOrderFromPos(response));
      setLoading(false);
    } else {
      dispatch(updatedOrderFromPos(null));
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };

  const updateOrderDetails = async (qrcode) => {
    let payload = {
      order_id: OrderDetails ? OrderDetails.id : null,
      qr_code_id: qrcode,
    };

    let res = await getUpdateOrderDetails(payload);
    if (res.status === 200 && res?.data?.payment_status === "1") {
      const total =
        res?.data?.total -
        (res?.data?.partial_payment_amount +
          res?.data?.specific_payment_amount);
      dispatch(addOrderDetails({ ...res?.data, total: total }));
      setLoaderPayBill(false);
    } else {
      dispatch(addOrderDetails(null));
      dispatch(updatedOrderFromPos(null));
      setLoading(false);
      setLoaderPayBill(false);
    }
  };

  const syncposAndsendValuestoDB = async () => {
    setLoaderPayBill(true);
    let qrid = Number(currentActive?.[0]?.qrcodeid);
    // setPosLoader(true);
    let req = {
      restaurent_id: RestuarantDetails?.restaurent_id,
      qr_code_id: RestuarantDetails?.id,
    };
    let res = await syncPOS(req);
    if (res?.status === 200) {
      updateOrderDetails(qrid);
      dispatch(PosIsReachable(true));
    }
    if (res?.response?.data?.status === 400) {
      updateOrderDetails(qrid);
    }
    if (res?.response?.status === 401) {
      // setPosLoader(false);
      toast.error(res.response.data.message, { theme: "colored" });
      dispatch(Logout([]));
    } else {
      if (res?.response?.data?.status === 500) {
        dispatch(PosIsReachable(false));
      }
    }

    //SyncOrderPayment
  };

  const syncOrderPaymentinDB = async () => {
    let req = {
      restaurent_id: RestuarantDetails?.restaurent_id,
      qr_code_id: RestuarantDetails?.id,
    };

    let res = await syncOrderPayemnt(req);
    if (res.status === 200) {
      // dispatch(authSucess(response));
      console.log("order payemnt sync");
    } else {
      // dispatch(authSucess(response));
      console.log("order payemnt sync error");
    }
  };

  const updatePosSyncStatus = async () => {
    let res = await PosSyncStatus();
    if (res.status === 200) {
      console.log("PosSyncStatus");
    } else {
      console.log("PosSyncStatus error");
    }
  };

  const updateDiscount = async () => {
    let qrcode = "";

    if (RestuarantDetails?.QrCodeGroup?.group_type === "Dine In") {
      qrcode = "Dine In";
    } else {
      if (isDeliveryEnable?.orderType === "takeaway") {
        qrcode = "Takeaway";
      } else {
        qrcode = "Delivery";
      }
    }

    let res = await updatedDiscountActive(qrcode);
    if (res.status === 200) {
      dispatch(updateActiveDiscount(res.data));
    }
  };

  async function getDiscount() {
    // setLoading(true);
    let qrcode = "";

    if (RestuarantDetails?.QrCodeGroup?.group_type === "Dine In") {
      qrcode = "Dine In";
    } else {
      if (isDeliveryEnable?.orderType === "takeaway") {
        qrcode = "Takeaway";
      } else {
        qrcode = "Delivery";
      }
    }
    let res = await getOverAllDiscount(currentActive?.[0]?.qrcodeid, qrcode);

    if (res.status === 200) {
      dispatch(overAllDiscount([res?.data]));
    } else {
      dispatch(overAllDiscount([]));
      // toast.error();
    }
  }

  const emptySplitModeInTakeawayDelivery = () => {
    if (RestuarantDetails?.QrCodeGroup?.group_type !== "Dine In") {
      dispatch(splitBill(null));
      dispatch(wayToPayBillType("PayFullBill"));
    }
  };

  const chekcingOrderDetails = async (restaurantid, qrcode) => {
    // setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    let res = await getCheckDetails(req);
    if (res.status === 200) {
      if (res?.message === "Order not found") {
        syncposAndsendValuestoDB(qrcode, restaurantid);
        return;
      } else {
        if (res?.data?.DUE_AMOUNT == 0) {
          syncposAndsendValuestoDB(qrcode, restaurantid);
          return;
        }
        if (res?.data?.TranferCheck > 0) {
          syncposAndsendValuestoDB(qrcode, restaurantid);
          return;
        }
        if (res?.data?.DUE_AMOUNT != OrderDetails?.total) {
          syncposAndsendValuestoDB(qrcode, restaurantid);
          return;
        }
      }
      setLoading(false);
      setLoaderPayBill(false);
      // setIsPOSEnable(true);
      dispatch(PosIsReachable(true));
    } else {
      if (res?.response?.data?.status === 400) {
        dispatch(PosIsReachable(false));
        dispatch(addOrderDetails(null));
      }
      if (res?.response?.data?.status === 500) {
        dispatch(PosIsReachable(true));
        updateOrderDetails(qrcode);
        return;
      }
      setLoading(false);
      setLoaderPayBill(false);
      // setMenuLoading(false);
    }
  };

  useEffect(() => {
    let id = Number(currentActive?.[0]?.qrcodeid);
    // setLoading(true)
    if (id !== null && id !== undefined) {
      dispatch(restartChat());
      getMenuItem(id);
    } else if (id === null && id === undefined) {
      setLoading(false);
      let msg = "Please scan Qr code again";
      setmessage(msg);
    } else {
      setLoading(false);
      let msg = "Please scan Qr code again";
      setmessage(msg);
    }
    if (POSisEnable) {
      setModel(false);
    }

    if (currentActive?.[0]?.qrcodeid && currentActive?.[0]?.restaurentid) {
      chekcingOrderDetails(
        currentActive?.[0]?.restaurentid,
        currentActive?.[0]?.qrcodeid
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActive?.[0]?.qrcodeid, POSisEnable, isDeliveryEnable]);

  useEffect(() => {
    // syncposAndsendValuestoDB();
    // syncOrderPaymentinDB();
    updatePosSyncStatus();
    updateDiscount();
    getDiscount();
    emptySplitModeInTakeawayDelivery();
  }, []);

  if (loading) {
    return (
      <Backdrop
        sx={{
          color: "#FC2B6E",
          background: "#ffffff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <LoaderWithAnimation
          style={{ background: customTheme?.background_color }}
        >
          <CircularProgress sx={{ color: customTheme?.theme_color }} />
          <p style={{ color: customTheme?.theme_color, marginTop: "5px" }}>
            Processing...
          </p>
        </LoaderWithAnimation>
      </Backdrop>
    );
  }
  return (
    <div style={{ height: "100vh" }}>
      {alertMessage && (
        <SwitchAccount
          open={alertMessage}
          handleClose={() => setAlertMessage(false)}
          // loadingSwitchAccount={false}
          // handleCustomerVerification={() => setAlertMessage(false)}
          parent={"verifieduser"}
        />
      )}

      <Header />
      {!RestuarantDetails?.Menu?.Restaurent?.disable_order &&
        tipContent?.tip_content_english &&
        tipContent?.tip_content_arabic && (
          <Tipheader
            message={
              Direction === "ltr"
                ? tipContent?.tip_content_english
                : tipContent?.tip_content_arabic
            }
          />
        )}

      {menu?.length > 0 ? (
        <>
          <AddFoodListWrapper
            dir={Direction}
            groupType={
              qrCodeGroup?.group_type === "Dine In" &&
              RestuarantDetails?.Orders?.length > 0 &&
              !isPaymentDisable
            }
          >
            <AllMenuListing
              handleMenuClick={handleMenuClick}
              menu={menu}
              id={id}
              open={open}
              anchorEl={anchorEl}
              direction={Direction}
              styleMenuBox={
                Direction === "ltr"
                  ? styleMenuBoxForEnglish
                  : styleMenuBoxForArabic
              }
            />

            <AdvertisementBanner />

            <div style={{ overflow: "scroll" }}>
              <SectionNavigation ref={categoryListRef}>
                {menu?.map((section, index) => (
                  <SectionNavigationBox
                    themeColor={customTheme?.theme_color}
                    backgroundCover={
                      section?.image
                        ? aqlutstorage + containerSection + section?.image
                        : null
                    }
                    onClick={() => handleMenuClick(section.id)}
                  >
                    <BlackLayer />
                    <p>
                      {Direction === "ltr"
                        ? section.section_name
                        : section.ar_section_name}
                    </p>
                  </SectionNavigationBox>
                ))}
              </SectionNavigation>
            </div>

            <SearchWrapper>
              <div className="SearchBar">
                <input
                  style={{ border: `1px solid ${customTheme?.theme_color}` }}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "addfoodlist.header.search.placeholder",
                  })}
                  onChange={(e) => searchDebouning(e.target.value)}
                />
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </SearchWrapper>

            {/* <MenuWrapper style={{ background: customTheme?.background_color }}>
              <div className="MenuIcon">
                <i onClick={handleClick}>
                  <MenuList color={customTheme?.text_color} />
                </i>
              </div>
              <div className="MenuList">
                <ul>
                  {menu?.map((section, key) => (
                    <>
                      {section?.Items?.length > 0 && (
                        <li
                          onClick={() => handleMenuClick(section.id)}
                          style={{ color: customTheme?.text_color }}
                        >
                          {Direction === "ltr"
                            ? section.section_name
                            : section.ar_section_name}
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              </div>
            </MenuWrapper> */}

            {searchLoading ? (
              <div
                style={{
                  display: "block",
                  textAlign: "center",
                  paddingTop: "52%",
                  paddingBottom: "37%",
                }}
              >
                <CircularProgress sx={{ color: customTheme?.theme_color }} />
              </div>
            ) : (
              <div
                // className="menuMainWrapper"
                style={{ background: customTheme?.background_color }}
              >
                <>
                  {searchData && searchData?.length > 0 ? (
                    <>
                      <MainSectionWrapper>
                        {searchData.map((item, key) => (
                          <>
                            <SubSectionItemWrapper
                              boxBackground={customTheme?.placeholder_box_color}
                            >
                              <Item
                                key={key + 3}
                                item={item}
                                toggleDrawer={() => toggleDrawer(item)}
                              />
                            </SubSectionItemWrapper>
                          </>
                        ))}
                      </MainSectionWrapper>
                    </>
                  ) : (
                    <MainSectionWrapper onScroll={handleScroll}>
                      {menu?.map((section, index) => (
                        <div ref={refs[section.id]} className="food-category">
                          <MainSection
                            payload={section}
                            key={index}
                            toggleDrawer={toggleDrawer}
                          />
                        </div>
                      ))}
                    </MainSectionWrapper>
                  )}
                </>
              </div>
            )}

            {/* here started second  component */}
            {model && (
              <AddItemDrawer
                model={model}
                setModel={setModel}
                toggleAllegiesItem={toggleAllegiesItem}
                special_notes={special_notes}
                inputNone={inputNone}
                setAlertMessage={setAlertMessage}
              />
            )}

            {/* footer component start*/}

            {/* footer blog hide for now */}

            {/* cartData?.length > 0 */}
            {cartData?.length > 0 && (
              <ViewBasket
                ViewText={<IntlMessage id="addfoodlist.button.reviewOrder" />}
                handleViewAction={ViewCart}
              />
            )}

            {/* end of footer blog */}

            {/* footer component end*/}

            <SwipeableDrawer
              anchor={"bottom"}
              open={showItems}
              className="mui-item-wrapper"
              onClose={() => dispatch(toggleItem(false))}
            >
              <RecommendedItem onClose={() => dispatch(toggleItem(false))} />
            </SwipeableDrawer>

            <SwipeableDrawer
              anchor={"bottom"}
              open={allegiesModel}
              className="mui-item-wrapper"
              onClose={() => setallegiesModel(false)}
            >
              <AllergiesItem Close={() => setallegiesModel(false)} />
            </SwipeableDrawer>
          </AddFoodListWrapper>
        </>
      ) : (
        <h1
          style={{
            fontSize: "18px",
            textAlign: "start",
            margin: "20px",
          }}
        >
          {message}
        </h1>
      )}

      {checkingPosStatus() &&
        OrderDetails?.total > 0 &&
        qrCodeGroup?.group_type === "Dine In" && (
          <>
            {loaderPayBill ? (
              <PayBillButton
                button_text_color={customTheme?.button_text_color}
                button_primary_color={customTheme?.button_primary_color}
                button_secondary_color={customTheme?.button_secondary_color}
              >
                Loading...
              </PayBillButton>
            ) : (
              <PayBillButton
                button_text_color={customTheme?.button_text_color}
                button_primary_color={customTheme?.button_primary_color}
                button_secondary_color={customTheme?.button_secondary_color}
                onClick={() =>
                  navigate(
                    `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/paybill`
                  )
                }
              >
                <div>
                  <i>
                    <LaunchPayBillIcon />
                  </i>
                  <div>
                    <span>
                      <IntlMessage id="Snapay.LaunchBtnPayBill" />
                    </span>
                    <span>{OrderDetails?.total}</span>
                  </div>
                </div>
                <i>
                  <DoubleArrowRightIcon />
                </i>
              </PayBillButton>
            )}
          </>
        )}
    </div>
  );
}

const SubSectionItemWrapper = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 176px;
  max-height: 200px;
  padding: 12px 16px;
  //   background:red;
  margin: 12px 0;
  box-shadow: 0 3px 8px 4px rgb(0 0 0 / 15%);
  border-radius: 8px;
  background: ${({ boxBackground }) => boxBackground};
`;

const SearchWrapper = styled.div`
  margin: 0 0 10px 0;
  padding: 0 10px;

  .SearchBar {
    position: relative;
    width: 100%;
    input {
      width: 100%;
      height: 40px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 30px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b7f91;
      padding: 10px 35px;
      border-style: none;
    }
    img {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 12px;
      left: 15px;
    }
  }
`;

const SectionNavigationBox = styled.div`
  position: relative;
  width: 104px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nunito Sans", sans-serif;
  box-shadow: 0 3px 8px 2px rgb(0 0 0 / 11%);
  border-radius: 8px;
  text-align: center;
  color: #fff;
  background: ${({ backgroundCover, themeColor }) =>
    backgroundCover ? `url("${backgroundCover}")` : themeColor};
  background-size: cover;
  background-position: center;

  p {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 157%;
    z-index: 9;
  }
`;

const SectionNavigation = styled.div`
  width: fit-content;
  height: 100%;
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
  gap: 10px;
`;

const MainSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 14px;
  padding: 0 14px;

  height: calc(100vh - 245px);
  overflow-y: auto;
  padding: ${({ groupType }) => groupType && "0 0 75px"};

  @media (min-width: 640px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const LoaderWithAnimation = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;

  p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212bd;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }

  .aminationLogo {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
`;

const DrawerBannerWrapper = styled.div`
  width: 80%;
  height: 400px;
  padding: 20px;
  animation-name: slideInAnimation;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  // animation-iteration-count: 1;
  animation-delay: 0s;
  @keyframes slideInAnimation {
    0% {
      width: 80%;
      height: 22px;
      transform: translateY(0);
      opacity: 0.1;
    }

    100% {
      width: 80%;
      height: 400px;
      transform: translateY(100);
      opacity: 1;
    }
  }
`;

const MenuWrapper = styled.div`
  top: 124px;
  right: 0;
  left: 0;
  z-index: 18;
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 1);

  .MenuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }

  .MenuList {
    width: 100%;
    overflow: hidden;
  }
  .MenuList ul {
    display: flex;
    list-style: none;
    width: 100%;
    overflow-x: scroll;
    margin: 10px 0 0 0;
    cursor: pointer;
    &::-webkit-scrollbar {
      width: 100%;
      height: 10px;
    }
  }

  .MenuList ul li {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #242424;
    margin: 0 10px;
    white-space: nowrap;
    border-bottom: 2px solid transparent;

    :hover {
      border-bottom: 2px solid red;
    }
  }
`;

const AddFoodListWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  ${"" /* padding: 10px 10px; */}
  position: relative;

  .MenuWrapper {
    width: 100%;
    height: 51px;
    background: red;
  }

  .MenuButtonWrapper {
    position: fixed;
    bottom: 120px;
    right: ${({ dir }) => dir === "ltr" && "-15px"};
    left: ${({ dir }) => dir === "rtl" && "50px"};
    z-index: 99;
    transform: translateX(-50%);

    button {
      width: 50px;
      height: 65px;
      border-style: none;
      background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
      border-radius: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.06px;
      color: #ffffff;
    }
  }

  .FoodListingHeader {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #202020;
    padding: 5px 10px;
  }

  .FoodListingItems {
    padding: 5px 10px;
  }
  .menuMainWrapper {
    height: calc(100vh - 245px);
    overflow-y: auto;
    padding-bottom: ${({ groupType }) => (groupType ? "76px" : "20px")};
  }
`;

const styleMenuBoxForEnglish = {
  width: "100%",
  height: "380px",
  padding: "10px 0",
  background: "#ffff",
  color: "#000",
  borderRadius: "20px",

  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
};

const styleMenuBoxForArabic = {
  width: "100%",
  height: "380px",
  padding: "10px 0",
  background: "#ffff",
  color: "#000",
  borderRadius: "20px",
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
};
