import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ThemeButton } from "../../style/SnapayGobalStyle";
import { CreditCardIcon, CrossDrawerIcon } from "../../Utils/Icons";
import { SnapayGrayBackground } from "../../Utils/Images";
import {
  CreateSplitBillApi,
  executePayment,
  GetSplitBillDetailsApi,
  initiateSession,
  paymentOnSkipcash as paymentOnMyfatoorah,
  verifyPosCheckStatus,
} from "../../services/Collection";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import IntlMessage from "../../Utils/IntlMessage";
import { useIntl } from "react-intl";

const PayWithCreditCard = ({ Close }) => {
  const customTheme = useSelector((state) => state?.theme?.branding);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const [cardLoading, setCardLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [redirectPaymentGatewayURL, setRedirectPaymentGatewayURL] =
    useState(null);
  const orderDeails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );

  const SplitMode = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const tipAmount = useSelector((state) => state?.orderInformationSlice?.tip);
  const paymentMode = useSelector(
    (state) => state?.orderInformationSlice?.payBillType
  );

  const [paymentSession, setPaymentSession] = useState(null);
  const langDir = useSelector((state) => state?.selectLang);
  const intl = useIntl();
  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const DeliveryModule = useSelector(
    (state) => state?.deliveryModule?.orderType
  );

  const checkDeliveryCharges = () => {
    if (
      RestuarantDetails?.QrCodeGroup?.group_type === "Takeaway" &&
      DeliveryModule === "delivery" &&
      RestuarantDetails?.Menu?.Restaurent?.delivery_fee
    ) {
      return RestuarantDetails?.Menu?.Restaurent?.delivery_fee;
    } else {
      return 0;
    }
  };

  const getSession = async () => {
    setCardLoading(true);
    let req = {
      CustomerIdentifier: "123",
      restaurent_id: Number(currentActive?.[0]?.restaurentid),
    };
    let res = await initiateSession(req);
    if (res.status === 200) {
      console.log(res, "responseeeeeeeeeeeeeee");
      setPaymentSession(res?.data?.Data);
      setCardLoading(false);
    } else {
      setCardLoading(false);
      console.log("pos sync error");
    }
  };
  var config = {
    countryCode: paymentSession?.CountryCode, // Here, add your Country Code you receive from InitiateSession Endpoint.
    sessionId: paymentSession?.SessionId, // Here you add the "SessionId" you receive from InitiateSession Endpoint.
    cardViewId: "card-element",
    style: {
      cardHeight: 160,
      input: {
        color: "#646464",
        fontSize: "13px",
        fontFamily: "sans-serif",
        inputHeight: "32px",
        inputMargin: "10px",
        borderColor: "#5C5C5C",
        borderWidth: "1px",
        borderRadius: "6px",
        placeHolder: {
          holderName: "Name On Card",
          cardNumber: "0000-0000-0000-0000",
          expiryDate: "MM / YY",
          securityCode: "CVV",
        },
      },
    },
  };

  const initFatoorah = () => {
    if (window.myFatoorah) {
      const elmt = document.getElementById("card-element");
      console.log(elmt);
      window.myFatoorah.init(config);
    }
  };

  const findAmount = (type, mode) => {
    if (type === "fullpayment") {
      let fullpayment =
        mode === "executivepayment"
          ? orderDeails?.total +
            Number(tipAmount?.tip ?? 0) +
            checkDeliveryCharges()
          : orderDeails?.total + checkDeliveryCharges();
      return fullpayment;
    } else if (type === "CustomAmount") {
      let CustomAmount =
        mode === "executivepayment"
          ? Number(SplitMode?.total) + Number(tipAmount?.tip ?? 0)
          : Number(SplitMode?.total);
      return CustomAmount;
    } else if (type === "PayforYourItem") {
      let PayforYourItem =
        mode === "executivepayment"
          ? Number(SplitMode?.total) + Number(tipAmount?.tip ?? 0)
          : Number(SplitMode?.total);
      return PayforYourItem;
    } else if (type === "DivideEqually") {
      let DivideEqually =
        mode === "executivepayment"
          ? Number(SplitMode?.total) + Number(tipAmount?.tip ?? 0)
          : Number(SplitMode?.total);
      return DivideEqually;
    }
  };

  const handleCreateSplitBill = async (payload) => {
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      split_method_customer_count: payload?.split_method_customer_count,
      transaction_id: payload?.transaction_id,
      split_method_customer_paid: payload?.split_method_customer_paid,
      total_amount: orderDeails?.sub_total,
      due_amount: payload?.due_amount,
      paid_amount: payload?.amount,
      split_method_type: payload?.type,
    };

    let res = await CreateSplitBillApi(req);

    if (res.status === 200) {
      console.log("CreateSplitBillApi", res);
      // setTimeout(() => setRedirectPaymentGatewayURL(payload?.PaymentURL), 3000);
      setRedirectPaymentGatewayURL(payload?.PaymentURL);
    } else {
      console.log("CreateSplitBillApi", res);
      toast.error("Please try again");
    }
  };

  const handleGetSplitBillDetails = async (payload) => {
    let res = await GetSplitBillDetailsApi(payload?.orderId);
    console.log(res, "GetSplitBillDetailsApi");
    // return
    if (res?.status === 200) {
      if (res?.data?.transaction_id === null) {
        // split - custom amount
        if (payload?.type === "2") {
          // setRedirectPaymentGatewayURL(payload?.PaymentURL);
          let due_amount = orderDeails?.sub_total - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid: 1,
            due_amount: due_amount,
            split_method_customer_count: 1,
          };
          handleCreateSplitBill(tempObj);
        }

        // split - pay for you items
        if (payload?.type === "3") {
          // setRedirectPaymentGatewayURL(payload?.PaymentURL);
          let due_amount = orderDeails?.sub_total - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid: 1,
            due_amount: due_amount,
            split_method_customer_count: 1,
          };
          handleCreateSplitBill(tempObj);
        }

        //split - divide equally
        if (payload?.type === "4") {
          let due_amount = orderDeails?.total - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid: SplitMode?.noPeopleYouPayFor,
            due_amount: due_amount,
            split_method_customer_count: SplitMode?.noTotalPeople,
          };
          handleCreateSplitBill(tempObj);
        }
      } else {
        // split - custom amount
        if (payload?.type === "2") {
          // let due_amount = orderDeails?.sub_total - payload?.amount;
          if (res?.data?.type === "2") {
            let due_amount = res?.data?.due_amount - payload?.amount;
            let tempObj = {
              ...payload,
              split_method_customer_paid: 1,
              due_amount: due_amount,
              split_method_customer_count: 1,
            };
            handleCreateSplitBill(tempObj);
          } else {
            let due_amount = res?.data?.due_amount - payload?.amount;
            let tempObj = {
              ...payload,
              split_method_customer_paid:
                Number(res?.data?.split_method_customer_paid) + 1,
              due_amount: due_amount,
              split_method_customer_count:
                res?.data?.split_method_customer_count,
            };
            handleCreateSplitBill(tempObj);
          }
        }

        // split -  pay for your items
        if (payload?.type === "3") {
          let due_amount = res?.data?.due_amount - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid: 1,
            due_amount: due_amount,
            split_method_customer_count: 1,
          };
          handleCreateSplitBill(tempObj);
        }

        //split - divide equally
        if (payload?.type === "4") {
          // let due_amount = orderDeails?.sub_total - payload?.amount;
          let due_amount = res?.data?.due_amount - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid:
              Number(res?.data?.split_method_customer_paid) +
              SplitMode?.noPeopleYouPayFor,
            due_amount: due_amount,
            split_method_customer_count: res?.data?.split_method_customer_count,
          };
          handleCreateSplitBill(tempObj);
        }
      }
    } else if (res?.response?.data?.status === 400) {
      // split - custom amount
      if (payload?.type === "2") {
        // setRedirectPaymentGatewayURL(payload?.PaymentURL);
        let due_amount = orderDeails?.sub_total - payload?.amount;
        let tempObj = {
          ...payload,
          split_method_customer_paid: 1,
          due_amount: due_amount,
          split_method_customer_count: 1,
        };
        handleCreateSplitBill(tempObj);
      }

      // split - pay for you items
      if (payload?.type === "3") {
        // setRedirectPaymentGatewayURL(payload?.PaymentURL);
        let due_amount = orderDeails?.sub_total - payload?.amount;
        let tempObj = {
          ...payload,
          split_method_customer_paid: 1,
          due_amount: due_amount,
          split_method_customer_count: 1,
        };
        handleCreateSplitBill(tempObj);
      }

      //split - divide equally
      if (payload?.type === "4") {
        let due_amount = orderDeails?.sub_total - payload?.amount;
        let tempObj = {
          ...payload,
          split_method_customer_paid: SplitMode?.noPeopleYouPayFor,
          due_amount: due_amount,
          split_method_customer_count: SplitMode?.noTotalPeople,
        };
        handleCreateSplitBill(tempObj);
      }
    } else {
      console.log(res?.message);
    }
  };

  const myFatoorahPayment = async (payload) => {
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      restaurent_id: orderDeails?.restaurent_id,
      amount:
        paymentMode === "PayFullBill"
          ? findAmount("fullpayment")
          : SplitMode?.type === "CustomAmount"
          ? findAmount("CustomAmount")
          : SplitMode?.type === "PayforYourItem"
          ? findAmount("PayforYourItem")
          : SplitMode?.type === "DivideEqually" && findAmount("DivideEqually"),

      tip: tipAmount?.tip ?? 0,
      //full- 1 , partia; - 2 , spec - 3
      type:
        paymentMode === "PayFullBill"
          ? "1"
          : SplitMode?.type === "CustomAmount"
          ? "2"
          : SplitMode?.type === "PayforYourItem"
          ? "3"
          : SplitMode?.type === "DivideEqually" && "4",
      InvoiceId: payload?.InvoiceId,
    };

    if (SplitMode?.type === "PayforYourItem") {
      let tempArray = [];
      for (let i = 0; i < SplitMode?.selectedItems?.length; i++) {
        let tempObj = {
          order_item_id: SplitMode?.selectedItems?.[i]?.id,
          paid_amount:
            (SplitMode?.selectedItems?.[i]?.amount /
              SplitMode?.selectedItems?.[i]?.quantity) *
            SplitMode?.selectedItems?.[i]?.customQuantity,
          paid_quantity: SplitMode?.selectedItems?.[i]?.customQuantity,
        };
        tempArray.push(tempObj);
      }
      req.payment_items_details = tempArray;
    }
    let res = await paymentOnMyfatoorah(req);
    if (res.status === 200) {
      console.log("paymentOnMyfatoorah", res.data);
      setBtnLoading(false);
      let obj = {
        orderId: orderDeails?.id,
        transaction_id: res?.data?.id,
        type: req?.type,
        amount: req?.amount,
        PaymentURL: payload?.PaymentURL,
      };
      if (req?.type === "1") {
        setRedirectPaymentGatewayURL(payload?.PaymentURL);
      } else {
        handleGetSplitBillDetails(obj);
      }
    } else {
      setBtnLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  const getExecutePayment = async (response) => {
    setBtnLoading(true);
    let req = {
      SessionId: response.sessionId,
      InvoiceValue:
        paymentMode === "PayFullBill"
          ? findAmount("fullpayment", "executivepayment")
          : SplitMode?.type === "CustomAmount"
          ? findAmount("CustomAmount", "executivepayment")
          : SplitMode?.type === "PayforYourItem"
          ? findAmount("PayforYourItem", "executivepayment")
          : SplitMode?.type === "DivideEqually" &&
            findAmount("DivideEqually", "executivepayment"),
      restaurent_id: Number(currentActive?.[0]?.restaurentid),
      qrcodeid: Number(currentActive?.[0]?.qrcodeid),
      UserDefinedField: `Aqlut-${orderDeails?.id}`,
    };

    let res = await executePayment(req);
    if (res.status === 200) {
      console.log(res, "responseeeeeeeeeeeeeee");
      myFatoorahPayment(res?.data?.Data);
      // setOpenPayment3DSecure({
      //   show: true,
      //   data: res?.data?.Data,
      // });
    } else {
      setBtnLoading(false);
      console.log("pos sync error");
    }
  };

  const posCheckStatus = async () => {
    let params = new URLSearchParams();
    params.append("order_id", orderDeails ? orderDeails.id : null);
    let res = await verifyPosCheckStatus(params.toString());
    if (res.status === 200) {
      return true;
    } else {
      toast.error(res?.response?.data?.message || res?.message || res?.error, {
        theme: "colored",
      });
      return false;
    }
  };

  const paymentSubmit = async () => {
    const isPosCheckedLock = await posCheckStatus();
    isPosCheckedLock &&
      window.myFatoorah.submit().then(
        function (response) {
          getExecutePayment(response);
        },
        function (error) {
          // In case of errors
          console.log(error);
        }
      );
  };

  const openSkipCashPaymentGateway = () => {
    console.log("redirectPaymentGatewayURL", redirectPaymentGatewayURL);
    if (redirectPaymentGatewayURL) {
      window.open(redirectPaymentGatewayURL, "_self");
    }
  };

  useEffect(() => {
    openSkipCashPaymentGateway();
  }, [redirectPaymentGatewayURL]);

  useEffect(() => {
    if (paymentSession) {
      initFatoorah();
    }
  }, [paymentSession]);

  useEffect(() => {
    getSession();
  }, []);

  return (
    <PayWithCreditCardStyle
      textColor={customTheme?.text_color}
      background={SnapayGrayBackground}
      dir={langDir?.direction}
    >
      <div className="heading">
        <span>
          <IntlMessage id="Snapay.CardBtn" />
        </span>
        <span onClick={Close}>
          <CrossDrawerIcon />
        </span>
      </div>

      {cardLoading ? (
        <div className="LoadingWrapper">
          <CircularProgress sx={{ color: "rgb(102, 67, 63)" }} />
        </div>
      ) : (
        <MyFatoorahWrapper id="card-element"></MyFatoorahWrapper>
      )}

      <ThemeButton
        button_text_color={customTheme?.button_text_color}
        button_primary_color={customTheme?.button_primary_color}
        button_secondary_color={customTheme?.button_secondary_color}
        type="submit"
        onClick={paymentSubmit}
      >
        {btnLoading ? (
          <CircularProgress sx={{ color: "white" }} size="20px" />
        ) : (
          <>
            <CreditCardIcon color={customTheme?.button_text_color} />
            <IntlMessage id="Snapay.Pay" />
          </>
        )}
      </ThemeButton>
    </PayWithCreditCardStyle>
  );
};

export default PayWithCreditCard;

const MyFatoorahWrapper = styled.div`
  .field-container div {
    width: 100%;
    padding: 14px 13px;
    border-radius: 6px;
    border: 1px solid #5c5c5c;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    margin: 5px 0;
  }
`;

const PayWithCreditCardStyle = styled.div`
  padding: 24px 24px 30px 24px;
  border-radius: 8px;
  background: ${({ background }) => `url(${background})`};
  background-size: contain;

  .heading {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  Form {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-top: 24px;
  }

  .inputField {
    width: 100%;
    padding: 14px 13px;
    border-radius: 6px;
    border: 1px solid #5c5c5c;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }
  .fieldWrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 17px;
    > div {
      width: 100%;
    }
  }

  .LoadingWrapper {
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
