import React, { useCallback, useState } from "react";
import Tip from "../../components/Tip";
import IntlMessage from "../../Utils/IntlMessage";
import { Button, CircularProgress, Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { LoadingWapper } from "../../style/GobalStyle";
import {
  ChangeModeOfPayment,
  addOrderCarDetails,
  addOrderOfficeRoomDetails,
  getOrderDetails,
  paymentOnSkipcash,
  orderPaymentStatus,
} from "../../services/Collection";
import { toast } from "react-toastify";
import { BillDetailStyle } from "../../components/BillDetail/BillDetail";
import { AmountWithDecimalValue } from "../../Utils/CommanFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowIcon, CreditCardIcon } from "../../Utils/Icons";
import { updateTip } from "../../store/orderPayment";
import PayWithCreditCard from "../../app/components/PayWithCreditCard";
import ApplePay from "../../app/components/ApplePay";
import Bowser from "bowser";
import { WhiteButton } from "../../style/SnapayGobalStyle";

export default function CartPrePayment({ handleViewAction }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingDataLoader, setFetchingDataLoader] = useState(true);
  const { orderDeails } = useSelector(({ orderInformationSlice }) => ({
    ...orderInformationSlice,
  }));

  // const RestaurentTheme = useSelector(
  //   (state) =>
  //     state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.MenuPageBranding
  // );
  const RestaurentTheme = useSelector((state) => state?.theme?.branding);
  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  console.log(RestuarantDetails?.delivery_fee, "RestuarantDetails");
  const [redirectPaymentGatewayURL, setRedirectPaymentGatewayURL] =
    useState(null);
  const checkQrCodeType = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.QrCodeGroup?.group_type
  );
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const currentID = currentActive?.[0]?.qrcodeid;
  const [openCarDetail, setOpenCarDetail] = useState(false);
  const [openOfficeRoomDetail, setOpenOfficeRoomDetail] = useState(false);
  const AuthDetails = useSelector((state) => state?.userAuth?.data);
  const Direction = useSelector((state) => state?.selectLang?.direction);
  const selectedType = useSelector((state) => state?.deliveryModule);
  const OrderID = useLocation()?.state?.id;
  const restaurentData = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const customTheme = useSelector((state) => state?.theme?.branding);

  const orderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const [carDetail, setCarDetail] = useState({
    numberPlate: "",
    type: "",
    color: "",
  });
  const [officeRoomDetail, setOfficeRoomDetail] = useState({
    floorNumber: "",
    officeRoomNumber: "",
  });
  const navigate = useNavigate();
  const [creditCardDrawer, setCreditCardDrawer] = useState();
  let platform = Bowser?.parse(window?.navigator?.userAgent);
  const orderTip = useSelector((state) => state?.orderInformationSlice?.tip);
  const orderSplit = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const paymentMode = useSelector(
    (state) => state?.orderInformationSlice?.payBillType
  );
  const isPaymentDisable = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.payment_enable
  );
  const [proceedPayDrawer, setProceedPayDrawer] = useState(false);

  var tipAmount = 0;
  const sendValues = (tips) => {
    if (tips.value !== "") {
      tipAmount = tips.value;
    } else {
      tipAmount = 0;
    }
  };

  const HandleApplePay = useCallback(() => {
    if (platform?.os?.name === "iOS") {
      return <ApplePay />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTip, orderSplit, paymentMode]);

  console.log(orderDeails, "orderDeailsorderDeails");

  const addingCarDetails = async () => {
    setLoading(true);
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      car_number: carDetail?.numberPlate ? carDetail?.numberPlate : "",
      car_type: carDetail?.type ? carDetail?.type : "",
      car_colour: carDetail?.color ? carDetail?.color : "",
      floor_number: officeRoomDetail?.floorNumber
        ? officeRoomDetail?.floorNumber
        : "",
      room_number: officeRoomDetail?.officeRoomNumber
        ? officeRoomDetail?.officeRoomNumber
        : "",
    };
    let res = await addOrderCarDetails(req);
    if (res.status === 200) {
      skipcashPayment();
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  const OrderConfirmedForCashOnDelivery = async () => {
    let obj = {
      payment_method: selectedType?.payment_method,
      // 1 - Online 2 - COD
      order_id: orderDetails?.id,
      address_id: selectedType?.selectedAddress,
      mode_of_payment: 5,
    };
    let res = await orderPaymentStatus(obj);
    if (res.status === 200) {
      if (selectedType?.payment_method == "1") {
        skipcashPayment(true);
      } else {
        toast.info(res.message, { theme: "colored" });
        navigate(`/${currentID}/pre-payment-order-details`);
      }
      setLoading(false);
    } else {
      toast.error(res.error || res.response.data.message);
      setLoading(false);
    }
  };

  const addingOfficeRoomDetails = async () => {
    setLoading(true);
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      car_number: carDetail?.numberPlate ? carDetail?.numberPlate : "",
      car_type: carDetail?.type ? carDetail?.type : "",
      car_colour: carDetail?.color ? carDetail?.color : "",
      floor_number: officeRoomDetail?.floorNumber
        ? officeRoomDetail?.floorNumber
        : "",
      room_number: officeRoomDetail?.officeRoomNumber
        ? officeRoomDetail?.officeRoomNumber
        : "",
    };
    let res = await addOrderCarDetails(req);
    if (res.status === 200) {
      if (
        restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
        selectedType?.orderType === "delivery"
      ) {
        // Send true parameter in case of delivery online paymnent
        OrderConfirmedForCashOnDelivery();
      } else {
        OrderConfirmedForCashOnArrival();
      }
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  const checkingQrcodewithCarDetails = () => {
    if (checkQrCodeType === "Takeaway") {
      if (RestuarantDetails?.enable_office_details) {
        // alert("reqiued");

        if (
          officeRoomDetail?.floorNumber !== "" &&
          officeRoomDetail?.officeRoomNumber !== ""
        ) {
          if (
            carDetail?.numberPlate === "" &&
            carDetail?.type === "" &&
            carDetail?.color === ""
          ) {
            addingCarDetails();
            // alert('office reuied... car optional')
          } else {
            if (carDetail?.numberPlate === "") {
              toast.error("Please enter number plate");
            } else if (carDetail?.type === "") {
              toast.error("Please enter car type");
            } else if (carDetail?.color === "") {
              toast.error("Please enter car colour");
            } else {
              // alert('bothhh reuied..')
              addingCarDetails();
            }
          }
        } else {
          if (officeRoomDetail?.floorNumber === "") {
            toast.error("Please enter floor number");
          } else if (officeRoomDetail?.officeRoomNumber === "") {
            toast.error("Please enter office/room number");
          }
        }
      } else {
        if (
          carDetail?.numberPlate === "" &&
          carDetail?.type === "" &&
          carDetail?.color === ""
        ) {
          skipcashPayment();
        } else {
          if (carDetail?.numberPlate === "") {
            toast.error("Please enter number plate");
          } else if (carDetail?.type === "") {
            toast.error("Please enter car type");
          } else if (carDetail?.color === "") {
            toast.error("Please enter car colour");
          } else {
            addingCarDetails();
          }
        }
      }
    } else {
      skipcashPayment();
    }
  };

  const cashOnArrival = () => {
    console.log(carDetail, officeRoomDetail, "--------cash on arrival");
    let flag = 0;
    // if (
    //   carDetail?.numberPlate !== "" ||
    //   carDetail?.type !== "" ||
    //   carDetail?.color !== "" ||
    //   officeRoomDetail?.floorNumber !== "" ||
    //   officeRoomDetail?.officeRoomNumber !== ""
    // ) {
    //   if (
    //     carDetail?.numberPlate !== "" ||
    //     carDetail?.type !== "" ||
    //     carDetail?.color !== ""
    //   ) {
    //     flag++;
    //     if (carDetail?.numberPlate === "") {
    //       toast.error("Please enter number plate");
    //     } else if (carDetail?.type === "") {
    //       toast.error("Please enter car type");
    //     } else if (carDetail?.color === "") {
    //       toast.error("Please enter car colour");
    //     } else {
    //       flag--;
    //     }
    //   }

    //   if (
    //     officeRoomDetail?.floorNumber !== "" ||
    //     officeRoomDetail?.officeRoomNumber !== ""
    //   ) {
    //     flag++;
    //     if (officeRoomDetail?.floorNumber === "") {
    //       toast.error("Please enter floor number");
    //     } else if (officeRoomDetail?.officeRoomNumber === "") {
    //       toast.error("Please enter office/room number");
    //     } else {
    //       flag--;
    //     }
    //   }
    //   if (flag === 0) addingOfficeRoomDetails();
    //   console.log(flag, "flagggggggggggggggg");
    // } else {
    //   OrderConfirmedForCashOnArrival();
    //   //DIRECT CALLING API FOR CONFORIMG ORDER
    // }

    if (RestuarantDetails?.enable_office_details) {
      // alert("reqiued");

      if (
        officeRoomDetail?.floorNumber !== "" &&
        officeRoomDetail?.officeRoomNumber !== ""
      ) {
        if (
          carDetail?.numberPlate === "" &&
          carDetail?.type === "" &&
          carDetail?.color === ""
        ) {
          addingOfficeRoomDetails();
          // alert('office reuied... car optional')
        } else {
          if (carDetail?.numberPlate === "") {
            toast.error("Please enter number plate");
          } else if (carDetail?.type === "") {
            toast.error("Please enter car type");
          } else if (carDetail?.color === "") {
            toast.error("Please enter car colour");
          } else {
            // alert('bothhh reuied..')
            addingOfficeRoomDetails();
          }
        }
      } else {
        if (officeRoomDetail?.floorNumber === "") {
          toast.error("Please enter floor number");
        } else if (officeRoomDetail?.officeRoomNumber === "") {
          toast.error("Please enter office/room number");
        }
      }
    } else {
      if (
        carDetail?.numberPlate === "" &&
        carDetail?.type === "" &&
        carDetail?.color === ""
      ) {
        OrderConfirmedForCashOnArrival();
      } else {
        if (carDetail?.numberPlate === "") {
          toast.error("Please enter number plate");
        } else if (carDetail?.type === "") {
          toast.error("Please enter car type");
        } else if (carDetail?.color === "") {
          toast.error("Please enter car colour");
        } else {
          addingOfficeRoomDetails();
        }
      }
    }
  };

  const cashOnDelivery = () => {
    console.log("RestuarantDetails", RestuarantDetails);

    OrderConfirmedForCashOnDelivery();

    // if (RestuarantDetails?.enable_office_details) {
    //   if (
    //     officeRoomDetail?.floorNumber !== "" &&
    //     officeRoomDetail?.officeRoomNumber !== ""
    //   ) {
    //     if (
    //       carDetail?.numberPlate === "" &&
    //       carDetail?.type === "" &&
    //       carDetail?.color === ""
    //     ) {
    //       addingOfficeRoomDetails();
    //       // alert('office reuied... car optional')
    //     } else {
    //       if (carDetail?.numberPlate === "") {
    //         toast.error("Please enter number plate");
    //       } else if (carDetail?.type === "") {
    //         toast.error("Please enter car type");
    //       } else if (carDetail?.color === "") {
    //         toast.error("Please enter car colour");
    //       } else {
    //         // alert('bothhh reuied..')
    //         addingOfficeRoomDetails();
    //       }
    //     }
    //   } else {
    //     if (officeRoomDetail?.floorNumber === "") {
    //       toast.error("Please enter floor number");
    //     } else if (officeRoomDetail?.officeRoomNumber === "") {
    //       toast.error("Please enter office/room number");
    //     }
    //   }
    // } else {
    //   if (
    //     carDetail?.numberPlate === "" &&
    //     carDetail?.type === "" &&
    //     carDetail?.color === ""
    //   ) {
    //     OrderConfirmedForCashOnDelivery();
    //   } else {
    //     if (carDetail?.numberPlate === "") {
    //       toast.error("Please enter number plate");
    //     } else if (carDetail?.type === "") {
    //       toast.error("Please enter car type");
    //     } else if (carDetail?.color === "") {
    //       toast.error("Please enter car colour");
    //     } else {
    //       addingOfficeRoomDetails();
    //     }
    //   }
    // }
  };

  const OrderConfirmedForCashOnArrival = async () => {
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      mode_of_payment: 7,
    };
    let res = await ChangeModeOfPayment(req);
    if (res.status === 200) {
      // setRedirectPaymentGatewayURL(res?.data?.resultObj);
      navigate(`/${currentID}/user-feedback`);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  console.log(data, "datattatattatat");

  const skipcashPayment = async (isDelivery) => {
    setCreditCardDrawer(true);
    setLoading(false);
  };

  const openSkipCashPaymentGateway = () => {
    if (redirectPaymentGatewayURL) {
      window.open(redirectPaymentGatewayURL?.payUrl, "_self");
    }
  };

  const handleSubmit = () => {
    if (
      orderDeails?.mode_of_payment == 8 ||
      orderDeails?.mode_of_payment == 9
    ) {
      if (
        restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
        selectedType?.orderType === "delivery"
      ) {
        cashOnDelivery();
      } else {
        cashOnArrival();
      }
    } else {
      if (
        restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
        selectedType?.orderType === "delivery"
      ) {
        cashOnDelivery();
      } else {
        checkingQrcodewithCarDetails();
      }
    }
  };

  const handlePaymentType = () => {
    handleViewAction();
    setProceedPayDrawer(true);
  };

  useEffect(() => {
    openSkipCashPaymentGateway();
  }, [redirectPaymentGatewayURL]);

  return (
    <>
      <div
        className="payment-listing"
        style={{
          background: customTheme?.background_color,
          border: `1px solid ${customTheme?.theme_color}`,
        }}
      >
        {orderDeails?.mode_of_payment != 8 &&
          orderDeails?.mode_of_payment != 9 && <Tip sendValues={sendValues} />}

        <WhiteButton
          button_text_color={customTheme?.button_text_color}
          button_primary_color={customTheme?.button_primary_color}
          button_secondary_color={customTheme?.button_secondary_color}
          onClick={handlePaymentType}
        >
          <IntlMessage id="Snapay.Proceedpay" />
        </WhiteButton>

        <Drawer
          anchor={"bottom"}
          open={proceedPayDrawer}
          onClose={() => setProceedPayDrawer(false)}
          className="mui-item-wrapper"
        >
          <DrawerStyle>
            {!isPaymentDisable && (
              <div className="paymentButton" style={{ gap: "20px" }}>
                {orderDeails?.mode_of_payment == 8 ||
                orderDeails?.mode_of_payment == 9 ? (
                  ""
                ) : (
                  <div style={{ width: "100%"}}>
                    <HandleApplePay />
                  </div>
                )}
                {loading ? (
                  <CircularProgress
                    sx={{ color: customTheme?.theme_color, my: 2 }}
                  />
                ) : (
                  <button
                    type="button"
                    // onClick={checkingQrcodewithCarDetails}
                    onClick={handleSubmit}
                    style={{
                      width: "100%",
                      height: "52px",
                      color: customTheme?.button_text_color,
                      background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                    }}
                  >
                    {orderDeails?.mode_of_payment == 8 ||
                    orderDeails?.mode_of_payment == 9 ? (
                      // selectedType?.payment_method != 2
                      "Confirm"
                    ) : (
                      <>
                        <CreditCardIcon
                          color={customTheme?.button_text_color}
                        />
                        <IntlMessage id="payment.button.pay" />
                      </>
                    )}
                  </button>
                )}
              </div>
            )}
          </DrawerStyle>
        </Drawer>
      </div>

      {creditCardDrawer && (
        <Drawer
          anchor={"bottom"}
          open={creditCardDrawer}
          className="mui-item-wrapper"
          disableSwipeToOpen
        >
          <PayWithCreditCard Close={() => setCreditCardDrawer(false)} />
        </Drawer>
      )}
    </>
  );
}

const DrawerStyle = styled.div`

.paymentButton , utton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-direction: column;
    padding: 20px 10px;
    border: 1px solid transparent;
    cursor: pointer;    
}

button{
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius : 8px;
}
`;
