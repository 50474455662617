/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
/* eslint-disable eqeqeq */
import { CircularProgress } from "@mui/material";
import moment from "moment";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Header } from "../../components";
import GoogleMapDistance from "../../components/GoogleMap/GoogleMapDistance";
import {
  debitPoints,
  getAllAddressInfo,
  getRestaurentStatus,
  placeOrder,
} from "../../services/Collection";
import { changeFreeItemStatus, emptyCartData } from "../../store/Cart";
import { addOrderDetails } from "../../store/orderPayment";
import { addRestaurentData } from "../../store/restaurenDetail";
import { redeemLoyalityPoints } from "../../store/UnifiedUser/UnifiedUser";
import { CalculateTime } from "../../Utils/CalculateTime";
import IntlMessage from "../../Utils/IntlMessage";
import {
  AddressWrapper,
  AvailableTimeWrapper,
  CartAddressBtn,
  CartAdressWrapper,
  DeliveryTimeBox,
  DeliveryWrapper,
  ScheduledTimeWrapper,
  SelectedAddressBox,
} from "./CartWithAddressStyle";

export const CartWithAdress = () => {
  const [deliveryTime, setDeliveryTime] = useState("now");
  const currentDay = (value) => {
    switch (value) {
      case 0:
        return "Sun";
        break;
      case 1:
        return "Mon";
        break;
      case 2:
        return "Tue";
        break;
      case 3:
        return "Wed";
        break;
      case 4:
        return "Thu";
        break;
      case 5:
        return "Fri";
        break;

      default:
        return "Sat";
        break;
    }
  };
  const dir = useSelector((state) => state?.selectLang?.direction);
  const navigate = useNavigate();
  const selectedAddressID = useLocation().state;

  const [dateActive, setDateActive] = useState("today");

  // const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  // const currentID = getAlreadyScanedID?.workStation?.slice(-1)?.[0]?.id;

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const currentID = currentActive?.[0]?.qrcodeid;
  const RestuarantDetails = useSelector((state) => state?.restaurentSlice);
  const RestaurentIsDeliverable = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  let RestaurentTime = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
        ?.RestaurentOperationalHours?.[0]
  );

  const UnifiedOrderType = useSelector(
    (state) => state?.isUnifiedSlice?.orderType
  );
  const [loading, setLoading] = useState(false);
  const [addressData, setAddressData] = useState();
  const [addressnotFound, setAddressNotFound] = useState(false);
  const deliveryDate = new Date().getDate();
  const nextdate = new Date().getDate() + 1;
  const deliveryDay = currentDay(new Date().getDay());
  const deliveryNextDay = currentDay(new Date().getDay() + 1);
  const dispatch = useDispatch();
  const [RedeemloyalityPoints, setRedeemloyalityPoints] = useState(0);
  const [getDeliveryTime, setgetDeliveryTime] = useState(null);

  const [distance, setDistance] = useState(null);

  const {
    cartData,
    totalPrice,
    subtotalPrice,
    QrData,
    service_tax,
    vat,
    discount,
    restaurentData,
    cartTotalDiscount,
  } = useSelector(({ cartSlice, restaurentSlice }) => ({
    ...cartSlice,
    ...restaurentSlice,
  }));

  const UnifiedInfo = useSelector((state) => state?.isUnifiedSlice);

  const res_delivery_fee = restaurentData?.Menu?.Restaurent?.delivery_fee;

  const TimeTable = (val) => (
    <div
      className="time-card"
      onClick={() =>
        val.toString().length == 1
          ? setgetDeliveryTime(`0${val}:00`)
          : setgetDeliveryTime(`${val}:00`)
      }
    >
      <span>{`${val}:00`}</span>
    </div>
  );

  const getAllAddress = async () => {
    setLoading(true);
    let res = await getAllAddressInfo();
    if (res.status === 200) {
      if (res.data.length == 0) {
        setLoading(false);
        setAddressNotFound(true);
      } else {
        setAddressData(res.data);
        setLoading(false);
        setAddressNotFound(false);
      }
    } else {
      setLoading(false);
      setAddressNotFound(true);
      toast.error(res.message);
    }
  };

  const handlePlaceOrderAndCheckDevilery = () => {
    if (UnifiedOrderType === "Delivery") {
      if (RestaurentIsDeliverable?.is_deliverable) {
        let distanceNumber = distance?.split(" ")?.[0];
        let checkDistanceUnit = distance?.split(" ")?.[1];
        let RestaurantDistance;
        if (checkDistanceUnit === "km") {
          RestaurantDistance = RestaurentIsDeliverable?.max_delivery_distance;
        } else if (checkDistanceUnit === "m") {
          RestaurantDistance =
            RestaurentIsDeliverable?.max_delivery_distance * 1000;
        }
        if (Number(RestaurantDistance) >= Number(distanceNumber)) {
          handlePlaceIOrder();
        } else {
          toast.warning(
            `Restaurant is only delivery within ${RestaurentIsDeliverable?.max_delivery_distance} KM.`,
            { theme: "colored" }
          );
        }
      } else {
        toast.error("Currently delivery is closed", { theme: "colored" });
      }
    } else {
      handlePlaceIOrder();
    }
  };

  function isOpen(openingHour, closingHour, currentHour) {
    if (closingHour <= openingHour) {
      // The restaurant closes the next day.
      if (currentHour >= openingHour || currentHour < closingHour) {
        return true;
      }
    } else {
      // The restaurant closes on the same day.
      if (currentHour >= openingHour && currentHour < closingHour) {
        return true;
      }
    }
    return false;
  }

  const handlePlaceIOrder = async () => {
    setLoading(true);
    // setLoadingButton(true);
    const currentHour = new Date();
    const payload = {
      restaurent_id: QrData?.restaurent_id,
    };
    const response = await getRestaurentStatus(payload);
    const { close_time, open_time, status } = response?.data;
    const { disable_order } = response?.data?.Restaurent;

    //Checking if resturent is accepting orders
    if (disable_order) {
      let alert = {
        message: "Currently restaurent is not accepting orders!",
      };
      navigate("/closed", {
        state: { message: alert },
      });

      return;
    }

    if (isOpen(open_time, close_time, currentHour)) {
      console.log("The restaurant is open");
    } else {
      console.log("The restaurant is closed");
      let alert = {
        message: "Currently restaurent is not accepting orders!",
      };
      navigate("/closed", {
        state: { message: alert },
      });
      return;
    }
    if (status === "1") {
      let alert = {
        message: `Restaurent is closed`,
      };
      navigate("/closed", {
        state: { message: alert },
      });

      return;
    }
    ///****************If restaurent is accepting order ************/
    //If all condition is satisfied only and only then plece order
    let addressPrimaryId = 0;
    addressData.map((el) => {
      if (el?.primary) addressPrimaryId = el.id;
    });
    let RedeemloyalityPoints = 0;

    if (UnifiedInfo?.isRedeemPoints) {
      if (UnifiedInfo?.orderType == "Delivery") {
        if (
          UnifiedInfo?.UnifiedCustomerInfo?.totalPoints >=
          Number(totalPrice) + res_delivery_fee
        ) {
          RedeemloyalityPoints = Number(totalPrice) + res_delivery_fee;
        } else if (
          UnifiedInfo?.UnifiedCustomerInfo?.totalPoints <
          Number(totalPrice) + res_delivery_fee
        ) {
          RedeemloyalityPoints = UnifiedInfo?.UnifiedCustomerInfo?.totalPoints;
        }
      } else {
        if (UnifiedInfo?.UnifiedCustomerInfo?.totalPoints >= totalPrice) {
          RedeemloyalityPoints = totalPrice;
        } else if (UnifiedInfo?.UnifiedCustomerInfo?.totalPoints < totalPrice) {
          RedeemloyalityPoints = UnifiedInfo?.UnifiedCustomerInfo?.totalPoints;
        }
      }
    } else {
      RedeemloyalityPoints = 0;
    }

    var address_id = selectedAddressID;
    if (addressData.length > 0) {
      for (let i = 0; i < addressData.length; i++) {
        if (selectedAddressID === null && addressData[i]?.primary)
          address_id = addressData[i]?.id;
      }
    }
    const obj = {
      restaurent_id: QrData?.restaurent_id,
      total: totalPrice,
      discount: discount,
      order_type: QrData?.group_type,
      qr_code_id: restaurentData?.id,
      qr_code_group_id: restaurentData?.qr_code_group_id,
      service_tax: service_tax,
      vat: vat,
      sub_total: subtotalPrice,
      currency: "qar",
      is_aura_user: true,
      is_redeem: UnifiedInfo?.isRedeemPoints,
      loyalty_points: RedeemloyalityPoints,
      order_type: UnifiedInfo?.orderType,
      address_id: UnifiedInfo?.orderType === "Delivery" ? address_id : false,
      delivery_date:
        deliveryTime === "now"
          ? 1
          : dateActive === "today"
          ? moment().format("YYYY-MM-DD")
          : moment().add("d", 1).format("YYYY-MM-DD"),
      delivery_time: deliveryTime === "now" ? 1 : getDeliveryTime + ":00",
    };

    let billAmount = Number(totalPrice);
    let loyaltyCoins = Number(RedeemloyalityPoints);

    const res = await placeOrder(obj);
    if (res.status === 200) {
      //Removing cart data
      setLoading(false);
      if (UnifiedInfo?.isRedeemPoints && RedeemloyalityPoints > 0)
        redeemdebitPoints(res?.data, RedeemloyalityPoints);
      dispatch(emptyCartData());
      dispatch(addOrderDetails(res?.data));

      getUpdateRestuarentSlice(res?.data);

      if (!UnifiedInfo?.isRedeemPoints) {
        if (UnifiedInfo?.UnifiedCustomerInfo?.totalPoints < totalPrice) {
          navigate(`/${currentID}/unified-payment`, { state: obj });
        } else {
          navigate(`/${currentID}/unified-payment`, { state: obj });
        }
      }
    } else {
      dispatch(changeFreeItemStatus(false));
      // setLoadingButton(false);
      toast.error(res.response.data.message);
      // setLoadingButton(false);
      setLoading(false);
    }
  };

  console.log(UnifiedInfo, "UnifiedInfo?.order_type");

  const getUpdateRestuarentSlice = (neworder) => {
    const newRestuarentData = RestuarantDetails?.restaurentData?.Orders?.filter(
      (item) => item.id !== neworder.id
    );
    newRestuarentData.push(neworder);
    let OrdersUpdated = {
      ...RestuarantDetails?.restaurentData,
      Orders: newRestuarentData,
    };
    dispatch(addRestaurentData(OrdersUpdated));
  };

  const redeemdebitPoints = async (data, points) => {
    let obj = {
      order_id: data?.id,
      amount: points,
    };
    setLoading(true);
    let response = await debitPoints(obj);

    if (response.status == 200) {
      // toast.success(response.message);
      setLoading(false);

      if (
        UnifiedInfo?.isRedeemPoints &&
        UnifiedInfo?.UnifiedCustomerInfo?.totalPoints >=
          Number(totalPrice) +
            (UnifiedOrderType === "Delivery" && Number(data?.delivery_fee))
      ) {
        navigate(`/${currentID}/pre-payment-order-details`, { state: data });
      } else if (!UnifiedInfo?.isRedeemPoints) {
        navigate(`/${currentID}/unified-payment`, { state: data });
      } else {
        navigate(`/${currentID}/unified-payment`, { state: data });
      }
    } else {
      setLoading(false);
      toast.error(response.message);
    }
  };

  const handleAddAddress = () => {
    navigate(`/${currentID}/map-location`);
  };

  useEffect(() => {
    getAllAddress();
  }, []);

  const [customerLat, setCustomerLat] = useState(null);
  const [customerLong, setCustomerLong] = useState(null);
  const isPrimaryData = () => {
    addressData?.map((ele) => {
      if (ele?.id == selectedAddressID) {
        setCustomerLat(ele?.latitude);
        setCustomerLong(ele?.longitude);
      } else if (selectedAddressID === null && ele?.primary) {
        setCustomerLat(ele?.latitude);
        setCustomerLong(ele?.longitude);
      }
    });
  };

  useEffect(() => {
    isPrimaryData();
  }, [addressData]);

  const Map = useMemo(() => {
    if (customerLat && customerLong) {
      return (
        <GoogleMapDistance
          setDistance={setDistance}
          customerLat={customerLat}
          customerLong={customerLong}
        />
      );
    }
  }, [customerLat, customerLong]);

  return !loading ? (
    <CartAdressWrapper>
      <div className="header-Wrapper">
        <Header />
      </div>
      {Map}
      <AddressWrapper dir={dir}>
        {UnifiedOrderType == "Delivery" ? (
          <>
            <div className="address-title">
              <label className="title">
                <IntlMessage id="cartAddress.screen.title" />
              </label>
              {!addressnotFound && (
                <label
                  onClick={() => navigate(`/${currentID}/select-location`)}
                  className="Change-label"
                >
                  <IntlMessage id="cartAddress.change" />
                </label>
              )}
            </div>
            <SelectedAddressBox>
              {addressnotFound ? (
                <h2 style={{ color: "gray" }}>
                  <IntlMessage id="cartAddress.noAddressFound" />
                </h2>
              ) : (
                <>
                  {addressData?.length > 0 &&
                    addressData?.map((el) =>
                      selectedAddressID === null
                        ? el?.primary && (
                            <>
                              <div className="SelectedAddress-title">
                                <h3>
                                  {el?.house_number}, {el?.street}, {el?.city}
                                </h3>
                              </div>
                              <div className="SelectedAddress-content">
                                <label>
                                  {el?.state}, {el?.country} - {el?.pin_code}
                                </label>
                              </div>
                            </>
                          )
                        : selectedAddressID == el?.id && (
                            <>
                              <div className="SelectedAddress-title">
                                <h3>
                                  {el?.house_number}, {el?.street}, {el?.city}
                                </h3>
                              </div>
                              <div className="SelectedAddress-content">
                                <label>
                                  {el?.state}, {el?.country} - {el?.pin_code}
                                </label>
                              </div>
                            </>
                          )
                    )}
                </>
              )}
            </SelectedAddressBox>
          </>
        ) : null}

        {!addressnotFound && (
          <>
            <DeliveryWrapper>
              <div className="address-title">
                {UnifiedOrderType == "Takeaway" ? (
                  <label className="title">
                    <IntlMessage id="unified.cartAddress.title.pickup" />
                  </label>
                ) : (
                  <label className="title">
                    <IntlMessage id="unified.cartAddress.title.delivery" />
                  </label>
                )}
              </div>

              <DeliveryTimeBox>
                <div className="delivery-time">
                  <input
                    type="radio"
                    name="deliveryTime"
                    value="now"
                    checked={deliveryTime == "now" ? true : false}
                    onChange={(e) => setDeliveryTime(e.target.value)}
                  />
                  <label>
                    {/* <IntlMessage id="cartAddress.now" /> */}
                    <IntlMessage id="cartAddress.now" />
                  </label>
                  <br />
                  <input
                    type="radio"
                    name="deliveryTime"
                    value="scheduled"
                    onChange={(e) => setDeliveryTime(e.target.value)}
                  />
                  <label>
                    <IntlMessage id="cartAddress.scheduled" />
                  </label>
                </div>
              </DeliveryTimeBox>
            </DeliveryWrapper>
            {deliveryTime == "scheduled" ? (
              <>
                <ScheduledTimeWrapper>
                  <div className="address-title">
                    <label className="title">
                      <IntlMessage id="cartAddress.scheduled.chooseDate" />
                    </label>
                  </div>
                  <div className="chooseDay">
                    <div
                      onClick={() => setDateActive("today")}
                      className={dateActive == "today" ? "active" : "day-cards"}
                    >
                      <span>{deliveryDay}</span>
                      <h4>{deliveryDate}</h4>
                    </div>
                    <div
                      onClick={() => setDateActive("tommorow")}
                      className={
                        dateActive == "tommorow" ? "active" : "day-cards"
                      }
                    >
                      <span>{deliveryNextDay}</span>
                      <h4>{nextdate}</h4>
                    </div>
                  </div>
                </ScheduledTimeWrapper>

                <AvailableTimeWrapper>
                  <div className="address-title">
                    <label className="title">
                      {" "}
                      <IntlMessage id="cartAddress.scheduled.availableTime" />
                    </label>
                  </div>

                  <div className="available-time-cards">
                    {RestaurentTime &&
                      CalculateTime(
                        dateActive,
                        RestaurentTime?.open_time,
                        RestaurentTime?.close_time
                      ).map((el) => {
                        return TimeTable(el);
                      })}
                  </div>
                </AvailableTimeWrapper>
              </>
            ) : null}
          </>
        )}
      </AddressWrapper>
      <CartAddressBtn>
        <div
          onClick={
            addressnotFound
              ? handleAddAddress
              : handlePlaceOrderAndCheckDevilery
          }
          className="ReviewOrderButton"
        >
          {addressnotFound ? (
            <p>
              <IntlMessage id="cartAddress.addAddress.button" />
            </p>
          ) : (
            <p>
              <IntlMessage id="cart.button.placeorder" />
            </p>
          )}
        </div>
      </CartAddressBtn>
    </CartAdressWrapper>
  ) : (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center ",
        height: "100vh",
      }}
    >
      <CircularProgress color="inherit" />
    </div>
  );
};
