import axios from "axios";

export const getLatLng = async (address) => {
  try {
    let data = await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyA3sQBgGGclgBv7qXQNX7r55YWoEJfTHHU`
      )
      .then((resp) => resp.data.results[0]);
    //   toast.info("To set accurate location you can use google map")
    return { ...data, status: true };
  } catch (e) {
    return { status: false, error: e };
    // toast.error("Something Went Wrong , Try after Sometime")
  }
};

export function AmountWithDecimalValue(x) {
  let n = Number(x).toFixed(2);
  if (n) {
    let res = n.split(".");
    if (res) {
      if (Number(res[1]) > 0) {
        return n;
      } else {
        return res[0];
      }
    }
  }
}

export const checkOrderIsFromPOS = (GetAllOrders) => {
  var flag = 0;
  if (GetAllOrders?.length > 0) {
    for (let i = 0; i < GetAllOrders.length; i++) {
      if (GetAllOrders[i].OrderItems.length > 0) {
        for (let j = 0; j < GetAllOrders[i].OrderItems.length; j++) {
          if (GetAllOrders[i].OrderItems[j].customer_name === "No customer") {
            flag++;
            return false;
          }
        }
        console.log(flag, "flagggggggggggggggg");
      } else {
        return true;
      }
    }
    if (flag === 0) {
      return true;
    }
  } else {
    return true;
  }
};

export const addCommaToNumbers = (num) => {
  let n = Number(num).toFixed(2);
  
  const numberFormatter = Intl.NumberFormat("en-US");
  const formatted = numberFormatter.format(n);
  if (formatted !== "NaN") {
    if (formatted.includes(".")) {
      return formatted;
    } else {
      return `${formatted}.00`;
    }
  } else {
    return "0.00";
  }
};

export const handleDineInCondition = (RestuarantDetails, location) => {
  if (RestuarantDetails?.QrCodeGroup?.group_type === "Menu") {
    return false;
  }
  if (RestuarantDetails?.Menu?.Restaurent?.disable_order) {
    return false;
  }
  if (
    RestuarantDetails?.QrCodeGroup?.group_type === "Dine In" &&
    location?.pathname?.includes("menu")
  ) {
    return true;
  }

  if (RestuarantDetails?.Menu?.Restaurent?.pos_integration_status) {
    if (RestuarantDetails?.Menu?.Restaurent?.disable_order_for_unsyncing) {
      return false
    } else {
      return true;
    }
  }

  return true;

};

export const handleConditionOfAddBasket = (
  userAuth,
  RestuarantDetails,
  restaurentid
) => {
  // return true; Means Auth Modal will open 
  if (userAuth?.IsMasterAuth?.length > 0 && userAuth?.IsMasterAuth?.some((v)=> v?.restaurent_id == restaurentid)) {
    if (RestuarantDetails?.QrCodeGroup?.group_type === "Menu") {
      return true;
    }
    if (RestuarantDetails?.Menu?.Restaurent?.disable_order) {
      return true;
    }
    return false;
  } else {
    return true;
  }
};

export const calculateCeiling = (value) => {
  if (value < 100) {
    return Math.ceil((value + 0.1) / 10) * 10;
  } else if (value >= 100 && value <= 400) {
    return Math.ceil((value + 0.1) / 20) * 20;
  } else {
    return Math.ceil((value + 0.1) / 50) * 50;
  }
};