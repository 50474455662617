import React, { useState } from "react";
import { CircularProgress, Skeleton, SwipeableDrawer } from "@mui/material";
import styled from "styled-components";
import IntlMessage from "../../Utils/IntlMessage";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import { LoadingWapper } from "../../style/GobalStyle";
import { useDispatch, useSelector } from "react-redux";
import { checkCustomerExists, createUser } from "../../services/Authapi";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { InitialScan, loginWithMasterQrCode } from "../../store/userLoginReducer";
import { useNavigate } from "react-router-dom";
import {
  isTakeawayDevliveryOrderType,
  swicthingMode,
} from "../../store/DeliveryModule/DeliverySlice";

export default function SwitchAccount({
  open,
  handleClose,
  loadingSwitchAccount,
  handleCustomerVerification,
  parent,
}) {
  const intl = useIntl();
  const [userInfo, setUserInfo] = useState({
    name: "",
    phoneNumber: "",
    countryCode: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    name: "",
    phoneNumber: "",
  });
  const [userInfoLoading, setUserInfoLoading] = useState(false);
  const [verifiedOtpLoading, setVerifiedOtpLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(null);
  const [isOtp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const getMultiUserData = useSelector(
    (state) => state?.userAuth?.IsMasterAuth
  );
  const { restaurentData } = useSelector(({ restaurentSlice }) => ({
    ...restaurentSlice,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customTheme = useSelector((state) => state?.theme?.branding);


  const handlePhoneNumberInput = (value, data) => {
    const dial_code = data?.dialCode;
    const phoneNumber = value.slice(data?.dialCode?.length, value?.length);
    setErrorMessage("");
    setUserInfo({
      name: userInfo.name,
      phoneNumber: phoneNumber,
      countryCode: dial_code,
    });
  };

  // const handlePhoneNumber = (inputNumber, country, countries) => {
  //   const dial_code = country.dialCode;
  //   const phoneNumber = inputNumber.slice(
  //     dial_code?.length,
  //     inputNumber?.length
  //   );
  //   setPhoneNumberWithoutDialcode(phoneNumber);
  //   setDialCode(dial_code);
  // };

  const userInfoSubmit = async () => {
    if (!userInfo.name) {
      setErrorMessage({
        name: null,
        phoneNumber: errorMessage.phoneNumber,
      });
      return;
    } else {
      setErrorMessage({
        name: "",
        phoneNumber: errorMessage.phoneNumber,
      });
    }
    setUserInfoLoading(true);
    if (
      userInfo?.phoneNumber?.length >= 8 &&
      userInfo?.phoneNumber?.length <= 12
    ) {
      let obj = {
        restaurent_id: currentActive?.[0]?.restaurentid,
        name: userInfo?.name,
        country_code: "+" + userInfo?.countryCode,
        mobile_no: userInfo?.phoneNumber,
      };
      //Check if customer is already exists or not?
      const res = await checkCustomerExists(obj);
      // if customer not exists then redirect user to verify OTP page
      if (res.status === 200) {
        setShowOtp(obj);
        // navigate(
        //   `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/verifyotp`,
        //   { state: { data: obj } }
        // );
        setUserInfoLoading(false);
        return;
      }
      //if customer  exists then save their info
      else if (res.status === 201) {
        setShowOtp(obj);
        // navigate(
        //   `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/verifyotp`,
        //   { state: { data: obj } }
        // );
        setUserInfoLoading(false);
        return;
      } else {
        toast.error(res.response.data.message, { theme: "colored" });
        setUserInfoLoading(false);
      }
    } else {
      setErrorMessage({
        name: errorMessage.name,
        phoneNumber: "Please enter a valid phone number",
      });
      setUserInfoLoading(false);
    }
  };

  // Verfiy OTP

  const storeTokenForMaster = (payload) => {
    let userDetails = { currentID: currentActive?.[0]?.qrcodeid, ...payload };
    if (getMultiUserData.length > 0) {
      const updatedArray = getMultiUserData?.filter(
        (item) => item.currentID !== userDetails.currentID
      );
      updatedArray.push(userDetails);
      if (updatedArray) dispatch(loginWithMasterQrCode(updatedArray));
    } else {
      let arr = [];
      arr.push(userDetails);
      if (arr && arr.length > 0) dispatch(loginWithMasterQrCode(arr));
    }
  };

  const handleNavigation = (responseData) => {
    let isTakeawayEnable = restaurentData?.Menu?.Restaurent?.enable_takeaway;
    let isDeliveryEnable = restaurentData?.Menu?.Restaurent?.enable_delivery;

    if (isTakeawayEnable && isDeliveryEnable) {
      navigate(
        `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/select-qrtype`,
        { state: responseData }
      );
    } else {
      if (isTakeawayEnable && !isDeliveryEnable) {
        dispatch(swicthingMode(isDeliveryEnable));
        dispatch(isTakeawayDevliveryOrderType("takeaway"));
        storeTokenForMaster(responseData);
      } else if (isDeliveryEnable && !isTakeawayEnable) {
        dispatch(swicthingMode(isDeliveryEnable));
        dispatch(isTakeawayDevliveryOrderType("delivery"));
        storeTokenForMaster(responseData);
      } else {
        alert("barcode is disabled, please scan another barcode");
      }
    }
  };

  const VerfiedOtpSubmit = () => {
    const otp = isOtp;
    console.log(otp, "otppppppppppppp");
    //Here we will verify the otp from backend and dispatch the success to redux store.
    if (otp && otp?.length == 4) {
      setVerifiedOtpLoading(true);
      let data = {
        restaurent_id: currentActive?.[0]?.restaurentid,
        name: showOtp.name,
        country_code: showOtp.country_code,
        mobile_no: showOtp.mobile_no,
        otp: otp,
      };
      //calling the API for save user information on server !Boom coder_0001
      createUser(data)
        .then((res) => {
          if (res.status === 200) {
            if (restaurentData?.QrCodeGroup?.group_type === "Takeaway") {
              dispatch(InitialScan(false));
              handleNavigation(res.data);
            } else {
              dispatch(InitialScan(false));
              storeTokenForMaster(res.data);
            }
            toast.success(res.message, { theme: "colored" });
          } else if (res.response.data.status === 409) {
            setVerifiedOtpLoading(false);
            toast.error("This phone number is already added!", {
              theme: "colored",
            });
          } else {
            setVerifiedOtpLoading(false);
            toast.error(res?.response?.data?.message, { theme: "colored" });
          }
        })
        .catch(() => {
          setVerifiedOtpLoading(false);
        });
    } else {
      setVerifiedOtpLoading(false);
      toast.error("Please enter a vaild OTP", { theme: "colored" });
    }
  };

  const ResendOTP = async () => {
    setResendLoader(true);
    if (userInfo) {
      let req = {
        // restaurent_id: userInfo?.restaurent_id,
        // name: userInfo?.name,
        // country_code: userInfo?.country_code,
        // mobile_no: userInfo?.mobile_no,

        restaurent_id: currentActive?.[0]?.restaurentid,
        name: showOtp.name,
        country_code: showOtp.country_code,
        mobile_no: showOtp.mobile_no,
        // otp: otp,
      };
      const res = await checkCustomerExists(req);
      if (res.status === 200) {
        setResendLoader(false);
        toast.success("OTP is successfully send", { theme: "colored" });
      } else if (res.status === 201) {
        setResendLoader(false);
        toast.success(res.message, { theme: "colored" });
      } else {
        setResendLoader(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
  };

  return (
    <SwipeableDrawer
      transitionDuration={{ appear: 1000, enter: 500, exit: 1000 }}
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      style={{
        borderRadius: "20px",
      }}
    >
      <SwitchAlertMessage>
        <p >
          {parent === "guestuser" ? (
            <>
              {showOtp == null ? (
                <IntlMessage id="authorization.SwitchAccountNote" />
              ) : (
                <IntlMessage id="verifyotp.button.entercode" />
              )}
            </>
          ) : (
            <IntlMessage id="orderitem.available_for_ordering" />
          )}
        </p>

        {parent === "guestuser" && showOtp == null && (
          <div>
            <input
              className="inputFeild"
              placeholder={intl.formatMessage({
                id: "orderverification.name.placeholder",
              })}
              onChange={(e) => {
                setUserInfo({
                  name: e.target.value,
                  phoneNumber: userInfo.phoneNumber,
                  countryCode: userInfo.countryCode,
                });
                setErrorMessage({
                  name: "",
                  phoneNumber: errorMessage.phoneNumber,
                });
              }}
            />
            {errorMessage?.name === null && (
              <ErrorMessage>Name is required.</ErrorMessage>
            )}

            <PhoneInputWrapper dir="ltr">
              <PhoneInput
                placeholder="Enter phone number"
                country={"qa"}
                onChange={(e, x) => handlePhoneNumberInput(e, x)}
                inputStyle={{ width: "100% !important", padding: "0 50px" }}
              />
            </PhoneInputWrapper>

            {errorMessage?.phoneNumber && (
              <ErrorMessage>{errorMessage?.phoneNumber}</ErrorMessage>
            )}

            {userInfoLoading ? (
              <LoadingWapper style={{ height: "35px", margin: "20px 0" }}>
                   <CircularProgress sx={{ color: `${customTheme?.theme_color} !important` }} />
              </LoadingWapper>
            ) : (
              <button onClick={userInfoSubmit}
              style={{
                color: customTheme?.button_text_color,
                background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
              }}
              >
                <IntlMessage id="orderverification.button.Verify" />
              </button>
            )}
          </div>
        )}

        {parent === "guestuser" && showOtp && (
          <VerifyOTPWrapper>
            <div dir="ltr">
              <OtpInput
                value={isOtp}
                onChange={setOtp}
                autoFocus
                OTPLength={4}
                isInputNum={true}
                disabled={false}
                secure
              />
            </div>

            <div style={{ margin: "0 0 20px 0" }}>
              <span>
                <IntlMessage id="verifyotp.notreceiveotp" />
              </span>
              {resendLoader ? (
                <Skeleton
                  variant="text"
                  sx={{
                    bgcolor: "#fc2b6e",
                    fontSize: "1rem",
                  }}
                />
              ) : (
                <label onClick={ResendOTP} style={{color: customTheme?.theme_color}} >
                  <IntlMessage id="verifyotp.resendotp" />
                </label>
              )}
            </div>

            {verifiedOtpLoading ? (
              <LoadingWapper style={{ height: "35px", margin: "20px 0" }}>
                 <CircularProgress sx={{ color: `${customTheme?.theme_color} !important` }} />
              </LoadingWapper>
            ) : (
              <button onClick={VerfiedOtpSubmit}
              style={{
                color: customTheme?.button_text_color,
                background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
              }}
              >
                <IntlMessage id="verifyotp.button.submit" />
              </button>
            )}
          </VerifyOTPWrapper>
        )}

        {parent === "verifieduser" && (
          <button onClick={handleCustomerVerification}
          style={{
            color: customTheme?.button_text_color,
            background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
          }}
          >
            {loadingSwitchAccount ? (
              <IntlMessage id="authorization.loading" />
            ) : (
              <IntlMessage id="button.close" />
            )}
          </button>
        )}
      </SwitchAlertMessage>
    </SwipeableDrawer>
  );
}

// test comment 
const VerifyOTPWrapper = styled.div`
  width: 100%;
  // position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffffff;
  border-radius: 30px 30px 0px 0px;
  // padding: 25px;

  div {
    header {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.36px;
      color: #202020;
      padding: 10px 0;
    }

    input {
      width: 48px !important;
      height: 48px;
      background: #ffffff;
      border: 1px solid #d2d2d3;
      border-radius: 28px;
      margin: 5px;
    }

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #7b7f91;
    }
    label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #fc2b6e;
      padding: 0 5px;
      margin: 10px 0;
    }

    button {
      width: 100%;
      height: 48px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.06px;
      color: #ffffff;
      background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
      border-radius: 30px;
      border-style: none;
      margin: 10px 0;
    }
  }
`;

const ErrorMessage = styled.span`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #fc2b6e;
`;

const PhoneInputWrapper = styled.div`
  margin: 10px 0;
  .react-tel-input .form-control {
    width: 100% !important;
    height: 48px !important ;
    background: #ffffff !important;
    border: 1px solid #d2d2d3 !important;
    border-radius: 28px !important;
    padding: 0 60px !important;
  }
  .react-tel-input .selected-flag .arrow {
    right: ${({ dir }) => dir === "rtl" && "20px"};
    left: ${({ dir }) => dir === "ltr" && "20px"};
  }

  .flag-dropdown {
    background: #ffffff !important;
    border: 1px solid #d2d2d3 !important;
    border-radius: 28px !important;
    padding: 0 3px !important;
    border-radius: ${({ dir }) => dir === "rtl" && "0 6px 6px 0 !important"};
    border-radius: ${({ dir }) => dir === "ltr" && "6px 0 0 6px !important"};
  }
`;

const SwitchAlertMessage = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  animation-name: slideInAnimation;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-delay: 0s;
  transition: transform 4s ease-in-out;
  @keyframes slideInAnimation {
    0% {
      width: 100%;
      height: 22px;
      transform: translateY(0);
      opacity: 0.1;
    }

    80% {
      width: 100%;
      height: 450px;
      transform: translateY(90);
      opacity: 0;
    }

    100% {
      width: 100%;
      height: 400px;
      transform: translateY(100);
      opacity: 1;
    }
  }

  p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    -webkit-letter-spacing: 0.36px;
    -moz-letter-spacing: 0.36px;
    -ms-letter-spacing: 0.36px;
    text-align: center;
    padding: 10px 0;
    color: #444041;
  }

  .inputFeild {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border: 1px solid #d2d2d3;
    border-radius: 28px;
    padding: 0 20px;
    margin: 10px 0;

    :focus {
      outline: none;
    }
  }

  button {
    width: 100%;
    height: 48px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.06px;
    color: #ffffff;
    background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
    border-radius: 30px;
    border-style: none;
    margin: 10px 0;
  }
`;
